import { useRecoilValue, useSetRecoilState } from 'recoil';

import type { ProjectListing } from '@/api/v4/projects.api';
import { type ProjectCompaniesSort } from '@/api/v4/projects.api';
import { useListingStages } from '@/features/listing-stage/use-listing-stages.query';
import { modalState } from '@/state/modal.state';
import type { ISort } from '@/ui/table/table.types';

import { getListingStageById } from '../../../listing-stage/helpers/get-listing-stage-by-id';
import { projectIdState } from '../../project.state';
import { projectListingSortState } from '../../project-listing.state';
import { useUpdateProjectListingStage } from '../use-update-project-listing-stage.mutation';

import { useProjectListingsTableConfiguration } from './use-project-listings-table-configuration';
import { InfiniteScrollTable } from '@/ui/table/infinite-table/infinite-scroll-table';
import type { FetchNextPageOptions } from '@tanstack/react-query';
import { StageManagementEmptyState } from '../stage-management-table/stage-management-empty-state';

export const ProjectListingsTable = ({
  listingsData,
  hasNextPage,
  onLoadMore,
  isLoading,
  isDisabledMode,
}: {
  listingsData: ProjectListing[];
  onLoadMore: (options?: FetchNextPageOptions) => Promise<unknown>;
  hasNextPage: boolean;
  isLoading: boolean;
  isDisabledMode?: boolean;
}) => {
  const projectId = useRecoilValue(projectIdState);
  const setModal = useSetRecoilState(modalState);
  const { listingStages } = useListingStages();

  const setSort = useSetRecoilState(projectListingSortState);

  const updateProjectListingStage = useUpdateProjectListingStage();

  const handleListingStageUpdate = async (
    listingId: string,
    newListingStageId: string | null,
    isDeal?: boolean,
  ) => {
    if (isDeal) {
      setModal({
        state: 'deal',
        listingId: listingId,
      });
    }

    if (!newListingStageId) return;
    const newStage = getListingStageById(newListingStageId, listingStages);

    if (!newStage) return;
    await updateProjectListingStage({
      projectListingId: listingId,
      newProjectListingStageId: newListingStageId,
    });
  };

  const handleSort = (update: ISort<ProjectCompaniesSort>) => {
    setSort({ direction: update.direction, sort: update.sort });
  };

  const { headers, rows } = useProjectListingsTableConfiguration({
    data: listingsData,
    onListingStatusChanged: handleListingStageUpdate,
  });

  if (!projectId && !isDisabledMode) {
    return null;
  }

  const tableHeight = listingsData.length > 6 ? '800px' : '400px';

  if ((!listingsData.length && !isLoading) || isDisabledMode) {
    return (
      <StageManagementEmptyState
        isProjectEmpty
        projectId={projectId ?? undefined}
      />
    );
  }

  return (
    <InfiniteScrollTable
      id="project-listing"
      data-testId="project-listing-table"
      sortState={projectListingSortState}
      data={listingsData}
      onSort={handleSort}
      rows={rows}
      headers={headers}
      isLoading={isLoading}
      onLoadMore={onLoadMore}
      hasMore={hasNextPage}
      height={tableHeight}
    />
  );
};
