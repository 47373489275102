import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { styled } from 'goober';
import { useRecoilState } from 'recoil';

import { OverviewPageContainer } from '@/components/layout/overview-page-container';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { ParagraphSmall } from '@/ui/typography/widgets';
import { WithHtmlSavvyTitle } from '@/with-html-savvy-title';

import { useActionPanel } from '../action-panel/use-action-panel';

import { useProject } from './overview/use-project.query';
import { projectIdState } from './project.state';
import { useActionPanelSearchParams } from '../action-panel/use-action-panel-search-params';
import { ProjectSidebar } from './overview/sidebar/project-sidebar';
import { useProjectSidebar } from './overview/sidebar/use-project-sidebar';
import { ProjectListings } from './project-listings/project-listings';
import { generateProjectCreatedBy } from './overview/helpers';
import { ProjectHeader } from './overview/header/project-header';
import { useUpdateProject } from './overview/use-update-project.mutation';
import {
  useProjectHeaderForm,
  type ProjectHeaderForm,
} from './overview/header/edit/use-project-header-form';
import { getEditFormDefaultValues } from './overview/header/helpers';
import { FormProvider } from 'react-hook-form';

export const ProjectOverviewPage = () => {
  const { projectId: projectIdParam } = useParams();
  const { t } = useTranslation('projects');
  const {
    closeActionPanel,
    openActionPanelOnSearchParamTab,
    isAlreadyOpenedOnLoad,
    setIsOpenOnLoad,
  } = useActionPanel();
  const [projectId, setProjectId] = useRecoilState(projectIdState);
  const { data: fetchedProject, isLoading, isFetched } = useProject();
  const { actionPanelSearchParam } = useActionPanelSearchParams();
  const { isOpen, handleChangeToggleProjectSidebar } = useProjectSidebar();
  const { editProject } = useUpdateProject();
  const methods = useProjectHeaderForm(
    getEditFormDefaultValues(fetchedProject),
  );
  const { getFieldState } = methods;

  useEffect(() => {
    setProjectId(projectIdParam ?? null);

    return () => {
      closeActionPanel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectIdParam]);

  useEffect(() => {
    if (
      !actionPanelSearchParam ||
      (actionPanelSearchParam && !isFetched) ||
      isAlreadyOpenedOnLoad
    ) {
      return;
    }

    openActionPanelOnSearchParamTab(actionPanelSearchParam, {
      context: 'project',
      title: fetchedProject?.name ?? '',
    });

    setIsOpenOnLoad(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, isAlreadyOpenedOnLoad]);

  const handleSaveChanges = async (values: ProjectHeaderForm) => {
    if (!projectId) {
      return;
    }

    const { isDirty: isNameDirty } = getFieldState('name');
    const { isDirty: isCreatedForDirty } = getFieldState('createdFor');
    const { isDirty: isStatusDirty } = getFieldState('status');
    const { isDirty: isTimeframeDirty } = getFieldState('timeframe');

    const { name, createdFor, status, timeframe } = values;

    const payload = {
      projectId,
      ...(isNameDirty && name && { name }),
      ...(isCreatedForDirty && {
        teamId: createdFor?.value ?? null,
      }),
      ...(isStatusDirty && { status }),
      ...(isTimeframeDirty &&
        timeframe && {
          ...timeframe,
        }),
    };

    await editProject(payload);
  };

  return (
    <WithHtmlSavvyTitle
      title={
        fetchedProject
          ? t(`projectOverview.page.title`, {
              projectName: fetchedProject.name,
            })
          : t`projects.project`
      }
    >
      <Relative>
        <OverviewPageContainer
          name="project-page-container"
          direction="column"
          justify="space-between"
        >
          <Main name="project-page-main-content" direction="column">
            <FormProvider {...methods}>
              <ProjectHeader onEditSubmit={handleSaveChanges} />
            </FormProvider>
            <ProjectListings isProjectLoading={!!isLoading} />
          </Main>
          <Footer>
            <ParagraphSmall>
              {fetchedProject && generateProjectCreatedBy(fetchedProject)}
            </ParagraphSmall>
          </Footer>
        </OverviewPageContainer>

        {isOpen && (
          <ProjectSidebar onClose={handleChangeToggleProjectSidebar} />
        )}
      </Relative>
    </WithHtmlSavvyTitle>
  );
};

const Relative = styled('div')`
  position: relative;
`;

const Main = styled(Flexbox)`
  flex-grow: 1;
`;

const Footer = styled('div')`
  padding-top: 40px;
`;
