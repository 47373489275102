import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useSetRecoilState } from 'recoil';

import { ListingStageName } from '@/features/listing-stage/types';
import { TOPBAR_HEIGHT } from '@/features/topbar/topbar';
import { modalState } from '@/state/modal.state';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { useListingStagesToSwitchTabs } from './use-listing-stages-to-switch-tabs';

interface Props {
  projectId?: string;
  isProjectEmpty: boolean;
}

export const StageManagementEmptyState = ({
  projectId,
  isProjectEmpty,
}: Props) => {
  const { t } = useTranslation('projects');
  const { hasRequiredPermission } = useUserPermissions();
  const hasEditPermission =
    hasRequiredPermission(PERMISSION.ADD_COMPANIES_TO_PROJECTS) &&
    Boolean(projectId);
  const setModalState = useSetRecoilState(modalState);
  const { setActiveTabByStageName } = useListingStagesToSwitchTabs();

  const handleAddCompany = () => {
    if (!projectId) {
      return;
    }

    setModalState({
      state: 'addCompany',
      projectId,
      onSuccess: () => setActiveTabByStageName(ListingStageName.discovery),
    });
  };

  return (
    <Container
      name="empty-state-container"
      justify="center"
      alignItems="center"
      direction="column"
      gap="10px"
    >
      {isProjectEmpty
        ? hasEditPermission
          ? t`addCompaniesToGetYourProjectGoing`
          : t`noCompaniesInThisProject`
        : t`noCompaniesInThisStage`}

      {hasEditPermission && (
        <Button variant="secondary" startIcon="Plus" onClick={handleAddCompany}>
          {t`addCompany.label`}
        </Button>
      )}
    </Container>
  );
};

const Container = styled(Flexbox)`
  width: 100%;
  height: calc(100vh - ${TOPBAR_HEIGHT} - 198px);

  ${({ theme }) => theme.typography.widget.paragraphMedium};
`;
