import { Z_INDEX_TABS_CONTAINER } from '@/theme/z-index';
import { styled } from 'goober';
import { Fragment, useTransition, type ReactNode } from 'react';
import { Flexbox } from '../flexbox/flexbox';
import { TabControl } from './tab-control';
import type { TabVariant } from './types';

export interface TabConfig<TabId> {
  id: TabId;
  content: ReactNode;
  title: ReactNode;
}
interface TabsProps<TabId> {
  activeTab: TabId;
  setActiveTab: (tabId: TabId) => void;
  tabsConfig: TabConfig<TabId>[];
  moveLastAtEnd?: boolean;
  noShadow?: boolean;
  border?: boolean;
  centerTabs?: boolean;
  padding?: string;
  className?: string;
  width?: string;
  variant?: TabVariant;
  withDividers?: boolean;
}

export const Tabs = <TabId extends string>({
  tabsConfig,
  activeTab,
  setActiveTab,
  moveLastAtEnd = false,
  noShadow = false,
  border = false,
  padding,
  className,
  width,
  centerTabs,
  variant = 'galaxy',
  withDividers,
}: TabsProps<TabId>) => {
  const [, startTransition] = useTransition();

  const switchTab = (tabId: TabId) => {
    startTransition(() => setActiveTab(tabId));
  };

  return (
    <Container className={className}>
      <TabsContainer
        name="tabs-container"
        moveLastAtEnd={moveLastAtEnd}
        noShadow={noShadow}
        padding={padding}
        border={border}
        width={width}
        justify={centerTabs ? 'space-around' : 'flex-start'}
        gap="12px"
      >
        {tabsConfig.map((tab, index, array) => {
          const isLastTab = array.length - 1 === index;

          return (
            <Tab
              title={tab.title}
              isActive={activeTab === tab.id}
              onClick={() => switchTab(tab.id)}
              variant={variant}
              isLastTab={isLastTab}
              withDividers={withDividers}
              key={tab.id}
            />
          );
        })}
      </TabsContainer>
      {tabsConfig.find(({ id }) => id === activeTab)?.content}
    </Container>
  );
};

interface TabProps {
  title: ReactNode;
  isLastTab: boolean;
  withDividers?: boolean;
  variant: TabVariant;
  onClick: () => void;
  isActive: boolean;
}

const Tab = ({
  variant,
  onClick,
  isLastTab,
  withDividers,
  isActive,
  title,
}: TabProps) => {
  return (
    <Fragment>
      <TabControl
        isActive={isActive}
        onClick={onClick}
        title={title}
        variant={variant}
      />
      {withDividers && !isLastTab && <Divider />}
    </Fragment>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

const TabsContainer = styled(Flexbox)<{
  moveLastAtEnd: boolean;
  noShadow: boolean;
  border: boolean;
  width?: string;
}>`
  /* to make box shadow visible over a table */
  position: relative;
  z-index: ${Z_INDEX_TABS_CONTAINER};

  ${({ theme, border }) =>
    border &&
    `
      border-bottom: 2px solid ${theme.colors.gray.c2};
  `};

  ${({ width }) => width && `width: ${width};`};

  ${({ theme, noShadow }) =>
    !noShadow &&
    `
      box-shadow: ${theme.shadow.widgets};
  `};
  ${({ moveLastAtEnd }) =>
    moveLastAtEnd &&
    `
      div:last-child {
        margin-left: auto;
      }
  `};
`;

const Divider = styled('div')`
  width: 1px;
  margin: 8px 20px;
  background-color: ${({ theme }) => theme.colors.gray.c7};
`;
