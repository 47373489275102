import type { Project } from '@/api/v4/projects.api';
import { formatDate } from '@/helpers/format-date';

import i18n from '@/lib/i18n/i18n';

export const generateProjectCreatedBy = (
  project: Pick<Project, 'creator' | 'createdDate'>,
) =>
  i18n.t('projects:projectOverview.createdBy', {
    userName: project.creator
      ? `${project.creator.firstName} ${project.creator.lastName}`
      : 'user',
    teamName:
      (project.creator?.team?.name && `, ${project.creator?.team.name}`) || '',
    date: project.createdDate ? formatDate(project.createdDate) : undefined,
  });
