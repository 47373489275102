import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { fetchProjectFileLink } from '@/api/v4/project-files.api';
import { QueryKey } from '@/config/query-client';
import type {
  DraftFile,
  File,
  FileLinkDownloadHandler,
  FileModeType,
} from '@/features/action-panel/files/file.interface';
import { FilesTabContent } from '@/features/action-panel/files/files-tab-content';
import type { UploadFileInputProps } from '@/features/action-panel/files/upload-file-input';
import { downloadLinkAutomatically } from '@/helpers/download-link-automatically';
import { modalState } from '@/state/modal.state';
import { notify } from '@/ui/snackbar/notify';

import { projectIdState } from '../../project.state';

import { useProjectFileScanningStatus } from './use-project-file-scanning-status.query';
import { useProjectFiles } from '../../overview/project-files/use-project-files.query';
import { useUploadProjectFile } from './use-upload-project-file.mutation';

export const ProjectFilesTab = () => {
  const { t } = useTranslation('default');
  const projectId = useRecoilValue(projectIdState);

  const { uploadProjectFile } = useUploadProjectFile();
  const queryClient = useQueryClient();
  const { data } = useProjectFiles();
  const files = data ?? [];

  const setModal = useSetRecoilState(modalState);

  if (!projectId) return null;

  const openDeleteModal = async (id: string, name: string) => {
    setModal({
      state: 'deleteProjectFile',
      file: { id, name },
      projectId,
    });
  };

  const handleSubmitFiles: UploadFileInputProps['onSubmit'] = async files => {
    if (!projectId) return;

    setDraftFiles(files);

    try {
      await uploadProjectFile(files);
    } catch (error) {
      if (error instanceof Error) {
        notify({ message: error.message });
      }
    }
  };

  const handleFileLinkDownload: FileLinkDownloadHandler = async (
    file: File,
    mode: FileModeType,
  ) => {
    if (!projectId) return;

    const link = await fetchProjectFileLink(
      {
        projectId,
        fileId: file.id,
      },
      {
        mode,
      },
    );

    await downloadLinkAutomatically(link, file.name);
  };

  const setDraftFiles = (uploadingFiles: globalThis.File[]) => {
    queryClient.setQueryData(
      [QueryKey.ProjectFiles, projectId],
      (files: Array<File | DraftFile> | undefined) => {
        if (!files) return;

        const draftFiles: DraftFile[] = uploadingFiles.map(file => ({
          id: file.name,
          name: file.name,
          createdDate: new Date().toISOString(),
          scanningStatus: null,
          creator: null,
          public: true,
          lastEditedDate: null,
        }));
        return [...draftFiles, ...files];
      },
    );
  };

  return (
    <FilesTabContent
      files={files}
      onFileLinkDownload={handleFileLinkDownload}
      emptyListLabel={t('actionPanel.files.empty', { context: 'project' })}
      onSubmit={handleSubmitFiles}
      onClickDelete={openDeleteModal}
      onShareFile={async () => void 0}
      allowToChangeFilePrivacy={false}
      useFileScanningStatus={useProjectFileScanningStatus}
    />
  );
};
