import { useRecoilValue } from 'recoil';
import { companyIdState } from '../company.state';
import { QueryKey } from '@/config/query-client';
import { useInfiniteQuery } from '@tanstack/react-query';
import { fetchOrganizationNews } from '@/api/v4/organization-news.api';

const COMPANY_NEWS_PAGE_SIZE = 15;

export const useCompanyNews = () => {
  const companyId = useRecoilValue(companyIdState);

  const getCompanyNews = async (nextPageParam: string) => {
    if (!companyId) {
      return {
        news: [],
      };
    }

    return await fetchOrganizationNews(companyId, {
      items: COMPANY_NEWS_PAGE_SIZE,
      nextCursorId: nextPageParam,
    });
  };

  const query = useInfiniteQuery(
    [QueryKey.CompanyNews, companyId],
    ({ pageParam }) => getCompanyNews(pageParam),
    {
      enabled: !!companyId,
      getNextPageParam: lastPage => {
        return lastPage.nextCursor;
      },

      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 5,
    },
  );

  return {
    news: query.data?.pages?.flatMap(page => page.news) ?? [],
    pageSize: COMPANY_NEWS_PAGE_SIZE,
    pages: query.data?.pages ?? [],
    ...query,
  };
};
