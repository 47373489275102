import type { AriaRole, MouseEvent } from 'react';
import { styled } from 'goober';

import * as icons from '@/assets/icons';

import { colorIcon } from './functions';

export type IconVariant = keyof typeof icons;

interface IconProps {
  icon: IconVariant;
  color?: string;
  width?: string;
  height?: string;
  centered?: boolean;
  className?: string;
  onClick?: (e?: MouseEvent<HTMLDivElement>) => void;
  role?: AriaRole;
  dataTestId?: string;
}

export const Icon = ({
  icon,
  color,
  centered = true,
  className,
  onClick,
  width,
  height,
  role,
  dataTestId,
}: IconProps) => {
  const IconComponent = icons[icon];
  const size = {
    ...(width && { width }),
    ...(height && { height }),
  };

  return (
    <IconWrapper
      color={color}
      centered={centered}
      className={className}
      onClick={onClick}
      role={role}
      data-testid={dataTestId}
    >
      <IconComponent {...size} />
    </IconWrapper>
  );
};

const IconWrapper = styled('div')<{
  color?: string;
  centered?: boolean;
}>`
  ${({ centered }) => centered && 'align-items: center; display: flex;'}
  ${({ color }) => color && colorIcon(color)};
  ${({ onClick }) => Boolean(onClick) && `cursor: pointer;`}
`;
