import { Inline, Stack } from '@/ui/line/line';
import SvgTags from '@/assets/widgets/empty-state-tags.svg';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { colorIcon } from '@/ui/icons/functions';
import { Icon } from '@/ui/icons/icon';
import { Flexbox } from '@/ui/flexbox/flexbox';
import type { Project } from '@/api/v4/projects.api';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import { ProjectTagsSkeleton } from './project-tags-skeleton';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { modalState } from '@/state/modal.state';
import { TagWithEllipsis } from '@/components/tag/tag-with-ellipsis';
import { PopperComponent } from '@/components/popper/popper-component';
import { Button } from '@/ui/button/button';

interface ProjectTagsProps {
  projectId: Project['id'];
  tags: Project['tags'];
  isLoading: boolean;
}

const renderTags = (tags: Project['tags'], onTagClick: () => void) => {
  return tags.map(tag => (
    <TagWithEllipsis
      key={tag.id}
      text={tag.name}
      variant="blue"
      onClick={onTagClick}
      startIcon="Label"
    />
  ));
};

export const ProjectTags = ({
  projectId,
  tags,
  isLoading,
}: ProjectTagsProps) => {
  const { t } = useTranslation('projects');
  const { hasRequiredPermission } = useUserPermissions();
  const setModal = useSetRecoilState(modalState);

  const firstFiveTags = tags?.slice(0, 5);
  const secondFiveTags = tags?.length > 5 ? tags?.slice(5, 10) : null;
  const moreThanTenTags = tags?.length > 10 ? tags?.slice(10) : null;

  const onEditClick = useCallback(() => {
    if (!projectId) return;

    setModal({
      state: 'manageTags',
      type: 'project',
      entityId: projectId,
    });
  }, [projectId, setModal]);

  if (isLoading) {
    return <ProjectTagsSkeleton />;
  }

  if (!tags?.length) {
    return (
      <Flexbox justify="space-between" alignItems="center">
        <ImageBox>
          <img
            src={SvgTags}
            alt="tags-illustration"
            width="100%"
            height="100%"
          />
        </ImageBox>
        <TextWrapper gap="12px">
          <LargeLabel>{t`projectOverview.emptyState.labelTags`}</LargeLabel>
          <ClickableText onClick={onEditClick}>
            <Icon icon="ArrowLong" />
            {hasRequiredPermission(PERMISSION.EDIT_PROJECT) && (
              <span>{t`projectOverview.emptyState.linkTextTags`}</span>
            )}
          </ClickableText>
        </TextWrapper>
      </Flexbox>
    );
  }

  return (
    <Stack>
      <Inline>{renderTags(firstFiveTags, onEditClick)}</Inline>
      {secondFiveTags && (
        <Inline justifyContent="flex-start">
          {renderTags(secondFiveTags, onEditClick)}
          {moreThanTenTags && (
            <PopperComponent
              placement="bottom-start"
              visibleElement={
                <ShowMoreButton variant="text">{`+${moreThanTenTags?.length}`}</ShowMoreButton>
              }
              PopperContent={
                <HiddenTagsContainer>
                  <Flexbox name="hidden-tags-popper" wrap="wrap">
                    {moreThanTenTags.map(tag => (
                      <HiddenTag
                        key={tag.id}
                        text={tag.name}
                        variant="blue"
                        onClick={onEditClick}
                        startIcon="Label"
                        size="xs"
                      />
                    ))}
                  </Flexbox>
                </HiddenTagsContainer>
              }
            />
          )}
        </Inline>
      )}
    </Stack>
  );
};

const ImageBox = styled('div')`
  padding: 19px 15px;
  width: 96px;
  height: 96px;
`;

const LargeLabel = styled('h1')`
  font-size: 22px;
  line-height: 32px;
  cursor: default;
`;

const ClickableText = styled('div')`
  ${({ theme }) => theme.typography.acrossPlatform.buttonText}
  color: ${({ theme }) => theme.colors.gray.c12};
  cursor: pointer;
  display: inline-flex;
  gap: 12px;
  ${({ theme }) =>
    `
      &:hover {
        color: ${theme.colors.blue.primaryA};
        ${colorIcon(theme.colors.blue.primaryA)}
      }
  `}
`;

const TextWrapper = styled(Stack)`
  flex-grow: 1;
  margin-left: 24px;
  max-width: 367px;

  @media (min-width: 1620px) {
    margin-left: 32px;
  }
`;

const ShowMoreButton = styled(Button)`
  color: ${({ theme }) => theme.colors.gray.c13};
`;

const HiddenTagsContainer = styled('div')`
  max-width: 400px;
  background-color: ${({ theme }) => theme.colors.basics.white};
  border-radius: 4px;
`;

const HiddenTag = styled(TagWithEllipsis)`
  margin: 5px;
`;
