import type { Tag } from '@/api/v4/organization-enrich.api';
import { styled } from 'goober';
import { Button } from '@/ui/button/button';
import { SkeletonText } from '@/ui/table/infinite-table/skeleton-row';
import { paths } from '@/routes/helpers/paths';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { TagLink } from '../../../../../components/tag/tag-link';
import { PopperComponent } from '@/components/popper/popper-component';
import { Stack } from '@/ui/line/line';
import { TooltipTitle } from './ui';
import { useTranslation } from 'react-i18next';

export const TagsCell = ({
  tags,
  isLoading,
}: {
  tags: Tag[];
  isLoading: boolean;
}) => {
  const { t } = useTranslation('default', {
    keyPrefix: 'globalSearch.suggestionsTable',
  });
  const visibleTags = tags.slice(0, 3);
  const hiddenTags = tags.length > 3 ? tags.slice(3) : [];

  const showMoreText = `+${hiddenTags?.length}`;

  return (
    <TagsContainer name="search-tags-results" alignItems="center">
      {isLoading ? (
        <SkeletonText width="80px" />
      ) : (
        <>
          {visibleTags.map(tag => (
            <TagLink
              key={tag.id}
              id={tag.id}
              name={tag.name}
              baseUrl={paths.companies()}
            />
          ))}
          {hiddenTags.length > 0 && (
            <PopperComponent
              placement="top"
              visibleElement={
                <ShowMoreButton variant="text">{showMoreText}</ShowMoreButton>
              }
              PopperContent={
                <HiddenTagsContainer gap="10px">
                  <TooltipTitle>{t`tags`}</TooltipTitle>
                  <Flexbox name="hidden-tags-popper" wrap="wrap">
                    {hiddenTags.map(tag => (
                      <TagLink
                        key={tag.id}
                        id={tag.id}
                        name={tag.name}
                        baseUrl={paths.companies()}
                        size="xs"
                      />
                    ))}
                  </Flexbox>
                </HiddenTagsContainer>
              }
            />
          )}
        </>
      )}
    </TagsContainer>
  );
};

const TagsContainer = styled(Flexbox)`
  flex-wrap: nowrap;
`;

const ShowMoreButton = styled(Button)`
  color: ${({ theme }) => theme.colors.gray.c13};
`;

const HiddenTagsContainer = styled(Stack)`
  max-width: 400px;
  background-color: ${({ theme }) => theme.colors.basics.white};
  padding: 15px 20px;
  border-radius: 4px;
`;
