import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { CreateProjectPayload } from '@/api/v4/projects.api';
import { createProject } from '@/api/v4/projects.api';
import { QueryKey } from '@/config/query-client';

import { useProjects } from './projects-list/use-projects.query';

export const useCreateProject = () => {
  const queryClient = useQueryClient();
  const { refetch: refreshProjects } = useProjects();

  const createProjectMutation = useMutation(createProject, {
    onSuccess: async () => {
      await refreshProjects();
      await Promise.all([
        queryClient.invalidateQueries([QueryKey.ProjectsKpi]),
        queryClient.invalidateQueries([QueryKey.ProjectOptions]),
        queryClient.invalidateQueries([QueryKey.CompaniesStatistics]),
        queryClient.invalidateQueries([QueryKey.ActiveProjects]),
        queryClient.invalidateQueries([QueryKey.LastActivities]),
        queryClient.invalidateQueries([QueryKey.LastActivitiesCount]),
      ]);
    },
  });

  const handleCreateProject = (payload: CreateProjectPayload) =>
    createProjectMutation.mutateAsync(payload);

  return {
    createProject: handleCreateProject,
    isError: createProjectMutation.isError,
    isCreating: createProjectMutation.isLoading,
  };
};
