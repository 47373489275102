import type {
  ContainerProps,
  ControlProps,
  MultiValueGenericProps,
  MultiValueRemoveProps,
  Props,
  StylesConfig,
} from 'react-select';
import { components } from 'react-select';
import { styled } from 'goober';

import { colors } from '@/theme/colors';
import { Z_INDEX_FILTERS_DROPDOWN_MENU } from '@/theme/z-index';
import { Icon } from '@/ui/icons/icon';
import { inputContainerMixin } from '@/ui/input/input-mixin';
import type { AsyncSelectProps } from '@/ui/select/async/async-select';
import type { CustomAsyncSelectProps } from '@/ui/select/async/use-select-type';
import type { TagVariant } from '@/ui/tag/variants';

export const sharedComponents: AsyncSelectProps['components'] = {
  ClearIndicator: undefined,
  IndicatorSeparator: null,
  Control: ({ children, ...props }: ControlProps) => (
    <components.Control {...props}>
      <ControlWrapper>{children}</ControlWrapper>
    </components.Control>
  ),
  MultiValueLabel: ({ children, ...props }: MultiValueGenericProps) => (
    <components.MultiValueLabel {...props}>
      <MultiValueLabelWrapper tagVariant={props.selectProps.tagVariant}>
        {children}
      </MultiValueLabelWrapper>
    </components.MultiValueLabel>
  ),
  MultiValueRemove: (props: MultiValueRemoveProps) => (
    <components.MultiValueRemove {...props}>
      <Icon icon="Close" color={colors.gray.c13} />
    </components.MultiValueRemove>
  ),
  MultiValueContainer: ({ children, ...props }: MultiValueGenericProps) => (
    <components.MultiValueContainer {...props}>
      <MultiValueContainerWrapper tagVariant={props.selectProps.tagVariant}>
        {children}
      </MultiValueContainerWrapper>
    </components.MultiValueContainer>
  ),
  SelectContainer: ({
    children,
    ...props
  }: ContainerProps<unknown, boolean>) => {
    return (
      <components.SelectContainer {...props}>
        {children}
      </components.SelectContainer>
    );
  },
};

export const sharedStyles: StylesConfig = {
  menu: base => ({
    ...base,
    zIndex: Z_INDEX_FILTERS_DROPDOWN_MENU,
  }),
  valueContainer: base => ({
    ...base,
    gap: '6px',
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: 'transparent',
  }),
  multiValueRemove: base => ({
    ...base,
    ':hover': {
      backgroundColor: 'transparent',
    },
  }),
  // Reset default styles
  control: () => ({}),
  option: (_base, props) => ({
    ...(props.isDisabled && { cursor: 'not-allowed' }),
  }),
  // Reset to default styles
  dropdownIndicator: base => ({ ...base }),
};

export const sharedProps: Partial<Props & CustomAsyncSelectProps> = {
  labelPlacement: 'bottom',
  styles: sharedStyles,
  components: sharedComponents,
};

export type SelectCustomSharedProps = {
  variant?: 'small' | 'regular';
  dropdownIndicatorColor?: string;
  dropdownIndicatiorTransform?: string;
  placeholderColor?: string;
  withError?: boolean;
  menuListHeight?: string;
  backgroundColor?: string;
};

export const getCustomSharedProps = ({
  variant = 'regular',
  dropdownIndicatorColor,
  dropdownIndicatiorTransform,
  placeholderColor,
  withError,
  menuListHeight,
  backgroundColor,
}: SelectCustomSharedProps) => {
  const { menu, ...rest } = sharedStyles;

  const styles: StylesConfig = {
    ...rest,
    dropdownIndicator: base => ({
      ...base,
      color: dropdownIndicatorColor ?? base.color,
      padding: variant === 'small' ? '0 8px' : base.padding,
      ...(dropdownIndicatiorTransform && {
        transform: dropdownIndicatiorTransform,
      }),
    }),
    singleValue: base => ({
      ...base,
      fontSize: variant === 'small' ? '12px' : base.fontSize,
    }),
    multiValue: base => ({
      ...base,
      backgroundColor: 'transparent',
      fontSize: variant === 'small' ? '12px' : base.fontSize,
    }),
    valueContainer: base => ({
      ...base,
      padding: variant === 'small' ? '0px 8px' : base.padding,
    }),
    placeholder: base => ({
      ...base,
      color: placeholderColor ?? colors.gray.c7,
    }),
    menuList: base => ({
      ...base,
      ...(menuListHeight && { maxHeight: menuListHeight }),
    }),
  };

  styles.menu = menu;

  const propsToReturn: Partial<
    Omit<Props, 'defaultValue'> & CustomAsyncSelectProps
  > = {
    labelPlacement: 'bottom',
    styles,
    components: {
      ...sharedComponents,
      Control: ({ children, ...props }: ControlProps) => (
        <components.Control {...props}>
          <ControlWrapper
            isSmallVariant={variant === 'small'}
            withError={withError}
            backgroundColor={backgroundColor}
          >
            {children}
          </ControlWrapper>
        </components.Control>
      ),
    },
  };

  return propsToReturn;
};

const MultiValueContainerWrapper = styled('div')<{ tagVariant?: TagVariant }>`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0;
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 4px;
  height: 30px;
  color: ${({ tagVariant = 'blue', theme }) =>
    theme.palettes.component.tag[tagVariant].text};
  background-color: ${({ tagVariant = 'blue', theme }) =>
    theme.palettes.component.tag[tagVariant].main};
`;

const MultiValueLabelWrapper = styled('div')<{ tagVariant?: TagVariant }>`
  ${({ theme }) => theme.typography.atoms.tags.general.s}
  color: ${({ tagVariant = 'blue', theme }) =>
    theme.palettes.component.tag[tagVariant].text};
  height: 100%;
`;

const ControlWrapper = styled('div')<{
  isSmallVariant?: boolean;
  withError?: boolean;
  isDisabled?: boolean;
  backgroundColor?: string;
}>`
  display: flex;
  ${({ theme }) => inputContainerMixin(theme)}
  gap: 5px;
  font-family: ${({ theme }) => theme.fonts.neue};
  padding: ${({ isSmallVariant }) => (isSmallVariant ? '4px 0px' : '2px 0px')};
  border: 2px solid ${({ theme }) => theme.colors.gray.c2};

  ${({ backgroundColor }) =>
    !!backgroundColor && `background-color: ${backgroundColor};`}
  ${({ isSmallVariant }) => !isSmallVariant && `min-height: 49px;`}
  ${({ isSmallVariant }) => isSmallVariant && `font-size: 12px;`}
  ${({ isSmallVariant }) => isSmallVariant && `line-height: 1;`}
  ${({ theme, withError }) =>
    withError && `border-color: ${theme.colors.system.lowFit};`}
    ${({ isDisabled }) => isDisabled && `cursor: not-allowed;`}
`;
