export const Z_INDEX_LOADER = 5000;
export const Z_INDEX_SIDEBAR = 3200; // higher than topbar z-index = 3100
export const Z_INDEX_SIDEBAR_DROPDOWN = Z_INDEX_SIDEBAR + 1;
export const Z_INDEX_MODAL_OVERLAY = 3500;
export const Z_INDEX_MODAL = 3600;
export const Z_INDEX_POPPER_CONTENT = Z_INDEX_MODAL + 1;

export const Z_INDEX_HIDDEN_INPUT = -999;

export const Z_INDEX_AUTH_PAGE_WHITE_BOX = 2;

export const Z_INDEX_PROJECTS_TABLE_HEAD = 1;
export const Z_INDEX_FILTERS_DROPDOWN_MENU = 2;
export const Z_INDEX_MENTIONS_DROPDOWN_MENU = 2;
export const Z_INDEX_TIMEFRAME_PICKER = 12;

export const Z_INDEX_STICKY_HEADER = 10;
export const Z_INDEX_SLIDING_SIDEBAR = Z_INDEX_STICKY_HEADER + 1;
export const Z_INDEX_DRAGGABLE_ROW = Z_INDEX_STICKY_HEADER + 1;

export const Z_INDEX_TABS_CONTAINER = 2;
