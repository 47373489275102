import type { ReactDatePickerProps } from 'react-datepicker';
import ReactDatePicker from 'react-datepicker';

import { GENERAL_DATE_FORMAT } from '@/constants';

import { DatePickerContainer } from './date-picker.styles';
import { DatePickerCustomHeader } from './date-picker-custom-header';

export function DatePicker(props: ReactDatePickerProps<string, boolean>) {
  return (
    <DatePickerContainer name="date-picker-new-container" direction="column">
      <ReactDatePicker
        data-testid="date-picker"
        disabledKeyboardNavigation
        calendarStartDay={1}
        renderCustomHeader={options => (
          <DatePickerCustomHeader {...options} isRange={props.selectsRange} />
        )}
        showPopperArrow={false}
        dateFormat={GENERAL_DATE_FORMAT}
        {...props}
      />
    </DatePickerContainer>
  );
}
