import type { Project } from '@/api/v4/projects.api';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export interface ProjectHeaderForm {
  name: string | null;
  status: Project['status'] | null;
  createdFor: { value: string; label: string } | null;
  timeframe: { timeframeStart: string; timeframeEnd: string } | null;
}

export const useProjectHeaderForm = (
  defaultValues: ProjectHeaderForm,
  allowForEmptyName = false,
) => {
  const { t } = useTranslation('projects');
  const projectHeaderFormValidationSchema = z.object({
    name: allowForEmptyName
      ? z.string().optional().nullable()
      : z.string().min(1, t('projectOverview.noEmptyName')),
    status: z.string().optional().nullable(),
    createdFor: z
      .object({ value: z.string(), label: z.string() })
      .optional()
      .nullable(),
    timeframe: z
      .object({ timeframeStart: z.string(), timeframeEnd: z.string() })
      .optional()
      .nullable(),
  });

  const methods = useForm<ProjectHeaderForm>({
    mode: 'all',
    reValidateMode: 'onBlur',
    resolver: zodResolver(projectHeaderFormValidationSchema),
    defaultValues,
  });

  return methods;
};
