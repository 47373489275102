import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import type { ProjectListing } from '@/api/v4/projects.api';
import { getListingStageByDefaultName } from '@/features/listing-stage/helpers/get-listing-stage-by-default-name';
import { isDealStage, ListingStageName } from '@/features/listing-stage/types';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import { modalState } from '@/state/modal.state';
import type { DropdownOption } from '@/ui/dropdown/dropdown';
import { WithDropdown } from '@/ui/dropdown/dropdown';
import { MenuButton } from '@/ui/menu-button/menu-button';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { useListingStages } from '../../../../listing-stage/use-listing-stages.query';
import { useUpdateProjectListingStage } from '../../use-update-project-listing-stage.mutation';

type ListingContextMenuItem =
  | 'goToCompanyPage'
  | 'moveToStage'
  | 'moveToNotRelevant'
  | 'sosaFeature'
  | 'removeFromProject';

const LISTING_STATUSES_CONTEXT_MENU: ListingStageName[] = [
  ListingStageName.discovery,
  ListingStageName.potentialFit,
  ListingStageName.contacted,
  ListingStageName.dueDiligence,
  ListingStageName.pilot,
  ListingStageName.deal,
];

export const ListingMenu = ({
  listing,
  blacklist,
}: {
  listing: ProjectListing;
  blacklist?: ListingContextMenuItem[];
}) => {
  const { t } = useTranslation('projects', {
    keyPrefix: 'listings',
  });
  const setModal = useSetRecoilState(modalState);
  const navigateTo = useNavigateTo();
  const { hasRequiredPermission } = useUserPermissions();
  const { listingStages } = useListingStages();
  const changeProjectListingStage = useUpdateProjectListingStage();

  const isNotRelevant = listing.notRelevant;

  const moveToStatus = async (newStageName: ListingStageName) => {
    const stage = getListingStageByDefaultName(newStageName, listingStages);
    if (!stage) return;
    await changeProjectListingStage({
      projectListingId: listing.id,
      newProjectListingStageId: stage.id,
    });
  };

  const menu: DropdownOption[] = [
    {
      label: t`goToCompanyPage`,
      isVisible:
        hasRequiredPermission(PERMISSION.ADD_ORGANIZATION) &&
        blacklist &&
        !blacklist.includes('goToCompanyPage'),
      onClick: () => navigateTo.company({ companyId: listing.organization.id }),
      icon: 'Check',
    },
    {
      label: t`moveStage`,
      isVisible:
        hasRequiredPermission(PERMISSION.UPDATE_COMPANY_LISTING_STATUS) &&
        blacklist &&
        !blacklist.includes('moveToStage'),
      icon: 'ArrowLeft',
      options: LISTING_STATUSES_CONTEXT_MENU.filter(
        listingStage =>
          !isDealStage(listingStage) &&
          (listingStages.find(stage => stage.name === listingStage) ||
            listingStage === ListingStageName.deal),
      ).map(listingStage => {
        const stage = getListingStageByDefaultName(listingStage, listingStages);
        return {
          label: stage?.displayName ?? stage?.name ?? listingStage,
          onClick: async () => {
            if (listingStage === ListingStageName.deal) {
              setModal({ state: 'deal', listingId: listing.id });
              return;
            }

            await moveToStatus(listingStage as ListingStageName);
          },
        };
      }),
    },
    {
      label: t`moveToNotRelevant`,
      isVisible:
        !isNotRelevant &&
        hasRequiredPermission(PERMISSION.UPDATE_COMPANY_LISTING_STATUS) &&
        blacklist &&
        !blacklist.includes('moveToNotRelevant'),
      onClick: async () => await moveToStatus(ListingStageName.notRelevant),
      icon: 'CloseCircle',
    },
    {
      label: t`removeFromProject`,
      isVisible:
        hasRequiredPermission(PERMISSION.REMOVE_COMPANIES_FROM_PROJECTS) &&
        blacklist &&
        !blacklist.includes('removeFromProject'),
      onClick: () => {
        setModal({
          state: 'deleteProjectListing',
          listingId: listing.id,
        });
      },
      icon: 'Bin',
    },
  ];

  return (
    <WithDropdown
      items={menu}
      options={{ placement: 'bottom-end' }}
      testId={`listing-menu-${listing.organization.id}`}
    >
      <MenuButton />
    </WithDropdown>
  );
};
