import { rgba } from '@/helpers/rgba';

const colors = {
  basics: {
    canvas: '#f9fbfc',
    white: '#ffffff',
    black: '#1d1d1d',
  },
  blue: {
    primaryA: '#3c59f6',
    primaryB: '#0d26bb',
    c2: '#98b5ff',
    c3: '#a8c0ff',
    c4: '#c2d3ff',
    c5: '#c9d8fc',
    c6: '#d6e1ff',
    c7: '#e7eeff',
    c8: '#edf2ff',
    c9: '#f4f7ff',
  },
  gray: {
    c1: '#f7f7fb',
    c2: '#edeef3',
    c3: '#e4e6f0',
    c4: '#e2e3eb',
    c5: '#dadbe5',
    c6: '#cfd2df',
    c7: '#c6c8d8',
    c8: '#babdd0',
    c9: '#afb3c9',
    c10: '#a0a4be',
    c11: '#9499B6',
    c12: '#6b719d',
    c13: '#63688b',
  },
  accent: {
    pink: {
      c1: '#fff4ff',
      c2: '#f385ff',
      c3: '#c948d8',
    },
    green: {
      c1: '#e3faf6',
      c2: '#85ffc4',
      c3: '#009099',
      c4: '#DAF8F3',
    },
    purple: {
      c1: '#efeaff',
      c2: '#b385ff',
      c3: '#8b00cc',
    },
  },
  system: {
    highFit: '#3c59f6',
    mediumFit: '#ffae63',
    lowFit: '#ff6363',
    disable: '#c1c1c1',
    active: '#00e1ab',
  },
} as const;

const productPalette = {
  primary: {
    splendidBlue: colors.blue.primaryA, // 1
    sapphireBlue: colors.blue.primaryB, // 2
  },
  blue: {
    ice: '#84a5fb', // 1
    sky: '#a8c0ff', // 2
    mist: '#c6c8d8', // 3
    lilacBlue: '#d6e1ff', // 4
    transparentBlue: '#d6e1ff', // 5
    morningDue: '#e7eeff', // 6
    cotton: '#edf2ff', // 7
    brilliant: '#f4f7ff', // 8
  },
  gray: {
    horizon: colors.gray.c13, // 1
    galaxy: colors.gray.c12, // 2
    moon: colors.gray.c11, // 3
    time: colors.gray.c10, // 4
    fantastic: colors.gray.c9, // 5
    stardust: colors.gray.c8, // 6
    mist: colors.gray.c7, // 7
    eclipse: colors.gray.c6, // 8
    powder: colors.gray.c5, // 9
    skyscraper: colors.gray.c4, // 10
    diamondCut: colors.gray.c3, // 11
    iceberg: colors.gray.c2, // 12
  },
  accentSystem: {
    pinkAccent: {
      c1: colors.accent.pink.c1,
      c2: colors.accent.pink.c2,
      c3: colors.accent.pink.c3,
    },
    greenAccent: {
      c1: colors.accent.green.c1,
      c2: colors.accent.green.c2,
      c3: colors.accent.green.c3,
    },
    purpleAccent: {
      c1: colors.accent.purple.c1,
      c2: colors.accent.purple.c2,
      c3: colors.accent.purple.c3,
    },
    raven: colors.basics.black,
    canvas: colors.basics.canvas,
    pureWhite: colors.basics.white,
    fit: {
      high: colors.blue.primaryA,
      medium: '#ffae63',
      low: '#ff6363',
      active: '#00e1ab',
    },
  },
} as const;

const brandPalette = {
  primary: {
    c1: colors.blue.primaryB, // primary
    c2: '#1630c7',
    c3: '#203ad3',
    c4: '#2945de',
    c5: colors.blue.primaryA, // splendid
    c6: colors.blue.c5,
  },
  accent: {
    pink: {
      c1: '#edb1ff',
      c2: colors.accent.pink.c2,
      c3: '#f190ff',
      c4: '#f09bff',
      c5: '#eea6ff',
      c6: '#efd6eb',
    },
    yellow: {
      c1: '#ffea4f',
      c2: '#f9dd69',
      c3: '#fbe062',
      c4: '#fce35c',
      c5: '#fee755',
      c6: '#f2e5b3',
    },
  },
} as const;

const componentsPalette = {
  loader: { background: 'rgba(0,0,0,0.25)' },
  tag: {
    transparent: {
      main: 'transparent',
      text: colors.gray.c13,
      hover: colors.gray.c13,
    },
    blue: {
      main: colors.blue.c8,
      text: colors.blue.primaryA,
      hover: colors.blue.primaryA,
    },
    darkBlue: {
      main: colors.blue.primaryA,
      text: colors.basics.white,
      hover: colors.basics.white,
    },
    purple: {
      main: colors.accent.purple.c1,
      text: colors.accent.purple.c3,
      hover: colors.accent.purple.c3,
    },
    pink: {
      main: colors.accent.pink.c1,
      text: colors.accent.pink.c3,
      hover: colors.accent.pink.c3,
    },
    green: {
      main: colors.accent.green.c1,
      text: colors.accent.green.c3,
      hover: colors.accent.green.c3,
    },
    gray: {
      main: colors.gray.c1,
      text: colors.gray.c13,
      hover: colors.system.highFit,
    },
    navy: {
      main: colors.blue.primaryB,
      hover: colors.blue.primaryB,
      text: colors.basics.white,
    },
  },
  button: {
    primary: {
      main: colors.blue.primaryA,
      text: colors.basics.white,
      hover: colors.blue.primaryB,
      hoverText: colors.basics.white,
      disabled: colors.gray.c7,
      disabledText: colors.basics.white,
    },
    secondary: {
      main: colors.blue.c8,
      text: colors.blue.primaryA,
      hover: colors.blue.c4,
      hoverText: colors.blue.primaryA,
      disabled: colors.gray.c7,
      disabledText: colors.basics.white,
    },
    navy: {
      main: colors.blue.primaryB,
      text: colors.basics.white,
      hover: colors.blue.primaryB,
      hoverText: colors.basics.white,
      disabled: colors.gray.c7,
      disabledText: colors.basics.white,
    },
    ghost: {
      main: 'transparent',
      text: colors.blue.primaryA,
      hover: colors.blue.c8,
      hoverText: colors.blue.primaryA,
      disabled: 'transparent',
      disabledText: colors.gray.c11,
    },
    white: {
      main: colors.basics.white,
      text: colors.blue.primaryA,
      hover: colors.blue.c8,
      hoverText: colors.blue.primaryA,
      disabled: colors.basics.white,
      disabledText: colors.gray.c11,
    },
    danger: {
      main: colors.system.lowFit,
      text: colors.basics.white,
      hover: colors.system.lowFit,
      hoverText: colors.basics.white,
      disabled: colors.system.disable,
      disabledText: colors.basics.white,
    },
    dangerText: {
      main: 'transparent',
      text: colors.system.lowFit,
      hover: rgba(colors.system.lowFit, 0.15),
      hoverText: rgba(colors.system.lowFit, 0.9),
      disabled: colors.system.disable,
      disabledText: colors.basics.white,
    },
    underlined: {
      main: 'transparent',
      text: colors.gray.c12,
      hover: 'transparent',
      hoverText: colors.gray.c12,
      disabled: 'transparent',
      disabledText: colors.system.disable,
    },
    text: {
      main: 'transparent',
      text: colors.basics.black,
      hover: colors.blue.c8,
      hoverText: colors.basics.black,
      disabled: 'transparent',
      disabledText: colors.system.disable,
    },
    purple: {
      main: colors.accent.purple.c1,
      text: colors.accent.purple.c3,
      hover: colors.accent.purple.c2,
      hoverText: colors.basics.white,
      disabled: 'transparent',
      disabledText: colors.system.disable,
    },
    gray: {
      main: colors.gray.c3,
      text: colors.gray.c12,
      hover: colors.gray.c12,
      hoverText: colors.gray.c3,
      disabled: 'transparent',
      disabledText: colors.system.disable,
    },
    black: {
      main: colors.basics.black,
      text: colors.basics.white,
      hover: colors.basics.black,
      hoverText: colors.basics.white,
      disabled: 'transparent',
      disabledText: colors.system.disable,
    },
  },

  tabs: {
    moon: {
      main: productPalette.gray.moon,
      active: colors.blue.primaryA,
    },
    galaxy: {
      main: productPalette.gray.galaxy,
      active: colors.blue.primaryA,
    },
  },

  dragDrop: {
    primary: {
      isStatic: {
        background: '#edf4ff',
        main: colors.blue.primaryA,
        border: colors.blue.primaryA,
      },
      isDragging: {
        background: colors.blue.primaryA,
        main: colors.basics.white,
        border: colors.blue.c4,
      },
      isOver: {
        background: colors.blue.primaryB,
        main: colors.basics.white,
        border: colors.blue.c4,
      },
    },
    secondary: {
      isStatic: {
        background: colors.basics.white,
        main: colors.gray.c12,
        border: colors.gray.c12,
      },
      isDragging: {
        background: colors.basics.white,
        main: colors.gray.c12,
        border: colors.gray.c12,
      },
      isOver: {
        background: colors.blue.c9,
        main: colors.blue.primaryB,
        border: colors.blue.primaryB,
      },
    },
  },
  chart: {
    label: colors.gray.c12,
    background: colors.blue.primaryA,
    hoverBackground: colors.blue.primaryB,
    disabledBackground: colors.blue.c7,
    disabledLabel: colors.blue.c7,
  },
  actionPanel: {
    tasks: {
      done: '#515479',
    },
  },
  company: {
    ranking: {
      cardBackground: '#E5FFFB',
    },
    editorNote: {
      backgroundColor: productPalette.blue.morningDue,
      readMoreTextColor: colors.blue.c3,
      editModeBackgroundColor: colors.basics.white,
      editModeHeaderBackgroundColor: colors.blue.c3,
      editIconColor: colors.basics.white,
      editIconBackgroundColor: colors.blue.primaryA,
    },
    editStamp: {
      color: colors.gray.c6,
    },
    activityStatusDot: {
      green: colors.system.active,
      red: colors.system.lowFit,
    },
    listedInProjects: {
      headerBackgroundColor: colors.blue.primaryB,
      headerTextColor: colors.basics.white,
    },
    verification: {
      verified: colors.blue.primaryA,
      aboutToExpire: productPalette.blue.ice,
      expired: colors.system.disable,
    },
  },
  explore: {
    page: {
      background: '#E9EBF4',
    },
    form: {
      background: '#F6F8FE',
    },
  },
  askX: {
    input: {
      background: '#4a4a4a',
      border: '#edeef333',
    },
  },
} as const;

export { brandPalette, colors, componentsPalette, productPalette };
