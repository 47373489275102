import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { notify } from '@/ui/snackbar/notify';

import { useInvalidateProjectListings } from '../../use-invalidate-project-listings';
import type { VoteUseCaseFitPayload } from '@/api/v4/project-listings.api';
import { voteProjectListingUseCaseFit } from '@/api/v4/project-listings.api';

export const useVoteUseCaseFit = (projectListingId: string) => {
  const { t } = useTranslation('default');
  const invalidateProjectListings = useInvalidateProjectListings();

  return useMutation(
    async (payload: VoteUseCaseFitPayload) => {
      return voteProjectListingUseCaseFit(projectListingId, payload);
    },
    {
      onSuccess: async () => await invalidateProjectListings(),
      onError: () => {
        notify({
          message: t('unknownError'),
        });
      },
    },
  );
};
