import { TagWithEllipsis } from '@/components/tag/tag-with-ellipsis';
import { useProjectFiles } from './use-project-files.query';
import { SkeletonBox } from '@/components/skeleton/skeleton-box';
import { Inline } from '@/ui/line/line';
import { useActionPanelSearchParams } from '@/features/action-panel/use-action-panel-search-params';
import { useActionPanel } from '@/features/action-panel/use-action-panel';

export const ProjectFiles = ({ projectName }: { projectName: string }) => {
  const { data, isLoading, isFetched } = useProjectFiles();
  const { setFileParam, actionPanelSearchParam } = useActionPanelSearchParams();
  const { openActionPanelOnSearchParamTab } = useActionPanel();

  if (isLoading) {
    return <SkeletonBox width="150px" height="20px" />;
  }

  if (isFetched && data?.length === 0) {
    return null;
  }

  const newestFiles = data?.slice(0, 4);

  const onFileClick = (fileId: string) => {
    setFileParam(fileId);
    if (!actionPanelSearchParam) return;
    openActionPanelOnSearchParamTab(actionPanelSearchParam, {
      context: 'project',
      title: projectName,
    });
  };

  return (
    <Inline justifyContent="flex-start">
      {newestFiles?.map(file => (
        <TagWithEllipsis
          key={file.id}
          text={file.name}
          variant="gray"
          startIcon="Attach"
          onClick={() => onFileClick(file.id)}
        />
      ))}
    </Inline>
  );
};
