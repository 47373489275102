import { Flexbox } from '@/ui/flexbox/flexbox';
import { Inline, Stack } from '@/ui/line/line';
import { styled } from 'goober';
import { ProjectTags } from '../tags/project-tags';
import { ProjectFiles } from '../project-files/project-files';
import { ProjectExternalLinksList } from '../external-links/project-external-links-list';

import { ProjectHeaderLeftBlock } from './project-header-left-block';
import type { ProjectHeaderForm } from './edit/use-project-header-form';
import { useProject } from '../use-project.query';

export interface ProjectHeaderProps {
  isLoading?: boolean;
  isCreateNewMode?: boolean;
  onEditSubmit: (values: ProjectHeaderForm) => Promise<void>;
}

export const ProjectHeader = ({
  isLoading,
  isCreateNewMode = false,
  onEditSubmit,
}: ProjectHeaderProps) => {
  const { data: project, isLoading: isDataFetching } = useProject();
  const showSkeleton = isCreateNewMode ? Boolean(isLoading) : isDataFetching;
  const shouldRenderRightBlock = !isCreateNewMode && project;

  return (
    <Container data-testid="project-overview-header">
      <BlocksWrapper templateColumns="55fr 45fr">
        <ProjectHeaderLeftBlock
          isLoading={showSkeleton}
          onEditSubmit={onEditSubmit}
          isCreateNewMode={isCreateNewMode}
        />

        {shouldRenderRightBlock && (
          <Flexbox fullHeight alignItems="center">
            <Stack gap="18px">
              <ProjectTags
                projectId={project?.id}
                tags={project?.tags ?? []}
                isLoading={isDataFetching}
              />
              <ProjectFiles projectName={project?.name} />
              <ProjectExternalLinksList />
            </Stack>
          </Flexbox>
        )}
      </BlocksWrapper>
    </Container>
  );
};

const Container = styled('div')`
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadow.project.header};
  padding: 42px 48px;
`;

const BlocksWrapper = styled(Inline)`
  gap: 30px;

  @media (min-width: 1620px) {
    gap: 40px;
  }
`;
