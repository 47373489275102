import type { CompanyExternalLink } from '@/api/v4/organization-external-links.api';
import ExpandArrows from '@/assets/widgets/expand-arrows.svg';
import { WIDGET_HEADER_MARGIN } from '@/features/companies/overview/utils/constants';
import { Input } from '@/ui/input/input';
import { Stack } from '@/ui/line/line';
import { SkeletonText } from '@/ui/table/infinite-table/skeleton-row';
import { Header, SubHeaderBold } from '@/ui/typography/widgets';
import type { EmptyStateWidgetProps } from '@/ui/widget/empty-state-widget';
import { EmptyStateWidget } from '@/ui/widget/empty-state-widget';
import { Widget } from '@/ui/widget/widget';
import { styled } from 'goober';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { ExternalLinkListItem } from './external-link-list-item';
import { useInputForm } from '../../hooks/use-input-form';

type ExternalLink = {
  id: string;
  urlValue: string;
};

interface ExternalLinksWidgetProps {
  className?: string;
  height: number;
  listMaxHeight?: number;
  addLinkHandler: (linkValue: string) => Promise<void>;
  removeLinkHandler: (linkId: string) => Promise<ExternalLink>;
  emptyStateConfig?: Partial<EmptyStateWidgetProps>;
  smallHeader?: boolean;
  linksData: ExternalLink[];
  isLoading: boolean;
}

const HeaderComponent = ({
  children,
  isSmall,
}: {
  children: React.ReactNode;
  isSmall: boolean;
}) => {
  if (isSmall) {
    return <SubHeaderContainer>{children}</SubHeaderContainer>;
  }

  return <HeaderContainer>{children}</HeaderContainer>;
};

export const ExternalLinksWidget = ({
  linksData,
  isLoading,
  addLinkHandler,
  removeLinkHandler,
  emptyStateConfig,
  className,
  height,
  listMaxHeight,
  smallHeader = false,
}: ExternalLinksWidgetProps) => {
  const { t } = useTranslation('companies', { keyPrefix: 'externalLinks' });
  const [isEditMode, setIsEditMode] = useState(false);
  const { clearInput, isInputDirty, inputRef } = useInputForm();

  const handleAddLink = async (link: CompanyExternalLink['urlValue']) => {
    await addLinkHandler(link);
    clearInput();
  };

  const onRemoveLink = async (id: string) => await removeLinkHandler(id);

  const onInputBlur = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      return;
    }

    await handleAddLink(e.target.value);
  };

  const onKeyUp = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputRef.current) {
      const linkValue = inputRef.current.value;

      if (!linkValue) {
        return;
      }

      await handleAddLink(linkValue);
    }
  };

  const handleOutsideClick = () => {
    if (isInputDirty) {
      return;
    }

    clearInput();
    setIsEditMode(false);
  };

  if (!isEditMode && !Boolean(linksData.length) && !isLoading) {
    return (
      <EmptyStateWidget
        height={height}
        onEditClick={() => setIsEditMode(true)}
        label={t`emptyStateLabel`}
        image={ExpandArrows}
        linkText={t`emptyStateLinkText`}
        gap={21}
        labelGap="20px"
        className={className}
        {...emptyStateConfig}
      />
    );
  }

  return (
    <div className={className}>
      <OutsideClickHandler onOutsideClick={handleOutsideClick}>
        <Widget
          height={height}
          showEditButton={!isEditMode}
          onEditClick={() => setIsEditMode(true)}
          header={
            <HeaderComponent isSmall={smallHeader}>
              {isLoading ? '' : t('header')}
            </HeaderComponent>
          }
        >
          {isLoading ? (
            <SkeletonText />
          ) : (
            <Stack gap="8px">
              {isEditMode && (
                <Input
                  placeholder={t`inputPlaceholder`}
                  onBlur={onInputBlur}
                  onKeyUp={onKeyUp}
                  ref={inputRef}
                />
              )}

              <List
                gap={isEditMode ? '15px' : '24px'}
                isListHigher={!isEditMode}
                maxHeight={listMaxHeight}
              >
                {linksData.map(link => (
                  <ExternalLinkListItem
                    key={link.id}
                    externalLink={link}
                    onRemoveClick={onRemoveLink}
                    isRemoveButtonVisible={isEditMode}
                  />
                ))}
              </List>
            </Stack>
          )}
        </Widget>
      </OutsideClickHandler>
    </div>
  );
};

const HeaderContainer = styled(Header)`
  margin: ${WIDGET_HEADER_MARGIN};
`;

const SubHeaderContainer = styled(SubHeaderBold)`
  margin: ${WIDGET_HEADER_MARGIN};
`;

const List = styled(Stack)<{ isListHigher: boolean; maxHeight?: number }>`
  padding-top: 8px;
  max-height: ${({ isListHigher, maxHeight }) =>
    isListHigher
      ? maxHeight
        ? `${maxHeight}px`
        : '210px'
      : maxHeight
      ? `${maxHeight - 60}px`
      : '150px'};
  overflow-y: auto;
  ${({ theme }) => theme.mixins.scrollbar};
`;
