import { useMemo } from 'react';
import type { QueryFunctionContext } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import type {
  ProjectCompaniesSort,
  ProjectListing,
  ProjectListingsResponse,
} from '@/api/v4/projects.api';
import { fetchProjectListings } from '@/api/v4/projects.api';
import { QueryKey } from '@/config/query-client';
import type { ISort } from '@/ui/table/table.types';

import { projectListingSortState } from '../project-listing.state';
import { getTimeInMs } from '@/helpers/get-time-in-ms';

export const useProjectListings = (
  projectId?: string | null,
  sort?: ISort<ProjectCompaniesSort>,
  enabled = true,
) => {
  const projectListingsSort = useRecoilValue(projectListingSortState);
  const sortParams = sort ?? projectListingsSort;

  const queryKey = useMemo(
    () => [
      QueryKey.ProjectListings,
      projectId,
      ...(sortParams ? [sortParams] : []),
    ],
    [projectId, sortParams],
  );

  const query = useInfiniteQuery<ProjectListingsResponse | null>({
    queryKey,
    queryFn: ({ pageParam = 1 }: QueryFunctionContext) => {
      if (!projectId) return null;

      const params = {
        ...sortParams,
        page: pageParam,
        items: 20,
      };

      return fetchProjectListings(projectId, params);
    },
    getNextPageParam: lastPage => {
      if (!lastPage) return undefined;

      const nextPage =
        lastPage.currentPage + 1 <= lastPage.totalPagesCount
          ? lastPage.currentPage + 1
          : undefined;

      return nextPage;
    },
    enabled: Boolean(projectId) && enabled,
    staleTime: getTimeInMs(5, 'minute'),
  });

  const projectListings: ProjectListing[] = useMemo(() => {
    return query.data?.pages.flatMap(page => page?.records ?? []) ?? [];
  }, [query.data]);

  return {
    projectListings,
    ...query,
  };
};
