import { useFormContext } from 'react-hook-form';

import { isDefaultOption } from '@/helpers/other';

import type { AsyncSelectProps } from '@/ui/select/async/async-select';
import { mapTeamsToBasicTeamOptions } from '@/api/v4/mappers/team-basic-option';
import type { ProjectHeaderForm } from './use-project-header-form';
import { FormSelect } from '@/features/explore/ui/form-select';
import { fetchTeams } from '@/api/v4/team.api';
import { colors } from '@/theme/colors';
import { styled } from 'goober';

export const CreatedForSelect = () => {
  const {
    setValue,
    formState: { defaultValues },
  } = useFormContext<ProjectHeaderForm>();

  const teamDefaultValue = defaultValues?.createdFor ?? null;

  const teamOptions: AsyncSelectProps['setOptions'] = async (
    search: string,
    page?: number,
  ) => {
    const options = await fetchTeams({
      search,
      page,
    });

    return mapTeamsToBasicTeamOptions(options);
  };

  return (
    <SelectWrapper>
      <FormSelect
        dataTestId="project-created-for-select"
        autoFocus
        defaultValue={teamDefaultValue}
        setOptions={teamOptions}
        onChange={(newValue: unknown, actionMeta) => {
          if (actionMeta.action === 'clear') {
            setValue('createdFor', null, {
              shouldDirty: true,
            });
          }

          if (isDefaultOption(newValue)) {
            setValue('createdFor', newValue, {
              shouldDirty: true,
            });
          }
        }}
        backgroundColor="white"
        variant="small"
        dropdownIndicatorColor={colors.basics.black}
      />
    </SelectWrapper>
  );
};

const SelectWrapper = styled('div')`
  width: 110px;
`;
