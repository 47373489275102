import { styled } from 'goober';

import { IconButton } from '@/ui/button/icon-button';
import { Flexbox } from '@/ui/flexbox/flexbox';

/* overwrite react-datepicker library styles */
export const DatePickerContainer = styled(Flexbox)`
  .react-datepicker-popper {
    z-index: 12;
  }

  .react-datepicker {
    padding: 14px;
    border-radius: 20px;
    border: none;
    box-shadow: ${({ theme }) => theme.shadow.actionsPanel};

    &__header {
      background-color: transparent;
      border: none;
      padding-top: 0;
      padding-bottom: 6px;
    }

    &__day {
      ${({ theme }) => theme.typography.widget.paragraphSmall}
      height: 42px;
      width: 42px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin: 2px;

      &-name {
        ${({ theme }) => theme.typography.actionsPanel.updates.quote}
        width: 42px;
        height: 37px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        margin-right: 4px;

        &:last-child {
          margin-right: 0;
        }
      }

      &-names {
        margin-top: 5px;
      }

      &--today {
        font-weight: 400;
      }

      &:not(&--range-start):not(&--range-end):hover,
      &--in-range,
      &--in-selecting-range {
        background-color: ${({ theme }) => theme.colors.blue.c7};
        color: ${({ theme }) => theme.colors.blue.primaryA};
      }

      &--range-start,
      &--range-end,
      &--selected,
      &--keyboard-selected {
        background-color: ${({ theme }) => theme.colors.blue.primaryA};
        color: ${({ theme }) => theme.colors.basics.white};
      }

      &--outside-month:not(&--in-range) {
        color: ${({ theme }) => theme.colors.gray.c10};
      }
    }
  }
`;

export const DatePickerHeader = styled(Flexbox)`
  ${({ theme }) => theme.typography.actionsPanel.comments.personName}
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.c7};
  background-color: transparent !important;
`;

export const ButtonDecreaseMonth = styled(IconButton)`
  transform: rotate3d(0, 0, 1, 180deg);
`;

export const ButtonIncreaseMonth = styled(IconButton)``;
