import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Project } from '@/api/v4/projects.api';
import {
  formatCalendarDate,
  parseNullishStringToDate,
} from '@/helpers/format-date';
import { CustomInputAsyncSelect } from '@/ui/date-picker/custom-input/custom-input-async-select';
import { DatePicker } from '@/ui/date-picker/date-picker';
import { type AsyncSelectProps } from '@/ui/select/async/async-select';

export interface TimeframeRange {
  timeframeStart: string | null;
  timeframeEnd: string | null;
}

interface DropdownTimeframeProps {
  defaultValue?: {
    timeframeStart: Project['timeframeStart'];
    timeframeEnd: Project['timeframeEnd'];
  };
  onChange: (range: TimeframeRange) => void | Promise<void>;
  disabled?: boolean;
  displayFormat?: string;
}

export function DropdownTimeframe({
  defaultValue,
  onChange,
  label,
  labelPlacement,
  components,
  styles,
  controlWidth,
  displayFormat,
  id,
  disabled = false,
}: DropdownTimeframeProps & Partial<Omit<AsyncSelectProps, 'onChange'>>) {
  const { t } = useTranslation('projects');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    if (!defaultValue) return;
    const { timeframeStart, timeframeEnd } = defaultValue;
    setStartDate(parseNullishStringToDate(timeframeStart));
    setEndDate(parseNullishStringToDate(timeframeEnd));
  }, [defaultValue]);

  const handleOnChange = async (date: [Date, Date | null]) => {
    const [start, end] = date;
    setStartDate(start);
    setEndDate(end);

    await onChange?.({
      timeframeStart: formatCalendarDate(start, 'yyyy-MM-dd'),
      timeframeEnd: formatCalendarDate(end, 'yyyy-MM-dd'),
    });
  };

  return (
    <DatePicker
      onChange={handleOnChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      id={id}
      dateFormat="yyyy-MM-dd"
      customInput={
        <CustomInputAsyncSelect
          displayFormat={displayFormat}
          label={label ?? t`projectOverview.timeframe`}
          controlWidth={controlWidth}
          disabled={disabled}
          labelPlacement={labelPlacement}
          components={components}
          styles={styles}
          isTimeFrame
        />
      }
    />
  );
}
