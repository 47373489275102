import { useFormContext } from 'react-hook-form';

import { isOption } from '@/helpers/other';

import type { ProjectHeaderForm } from './use-project-header-form';
import { FormSelect } from '@/features/explore/ui/form-select';
import { projectStatusOptions } from '../../dropdowns/project-status-options';
import { ProjectStatus } from '@/api/v4/projects.api';
import type { OptionType } from '@/ui/select/async/use-select-type';
import { styled } from 'goober';
import { colors } from '@/theme/colors';

interface OptionProjectStatus extends Omit<OptionType, 'value'> {
  value: ProjectStatus;
}

const isProjectStatusType = (
  option: unknown,
): option is OptionProjectStatus => {
  if (!isOption(option)) return false;

  const arr: string[] = Object.values(ProjectStatus);
  return arr.includes(option.value);
};

export const StatusSelect = () => {
  const {
    setValue,
    formState: { defaultValues },
  } = useFormContext<ProjectHeaderForm>();

  const defaultValue = projectStatusOptions.find(
    item => item.value === defaultValues?.status,
  );

  return (
    <SelectWrapper>
      <FormSelect
        dataTestId="project-status-select"
        autoFocus
        defaultValue={defaultValue}
        setOptions={projectStatusOptions}
        onChange={(selected: unknown, actionMeta) => {
          if (actionMeta.action === 'clear') {
            setValue('status', null, {
              shouldDirty: true,
            });
          }

          if (isProjectStatusType(selected)) {
            setValue('status', selected?.value, {
              shouldDirty: true,
            });
          }
        }}
        backgroundColor="white"
        variant="small"
        dropdownIndicatorColor={colors.basics.black}
      />
    </SelectWrapper>
  );
};

const SelectWrapper = styled('div')`
  margin-top: 24px;
  width: 110px;
`;
