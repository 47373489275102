import { Flexbox } from '@/ui/flexbox/flexbox';
import OutsideClickHandler from 'react-outside-click-handler';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { StatusIndicator } from '../../status-indicator';
import { DetailBlock } from './detail-block';
import { ProjectHeaderFirstRow } from './project-header-first-row';
import { ProjectHeaderSkeleton } from './project-header-skeleton';
import {
  isProjectHeaderHovered,
  isProjectHeaderEditMode,
} from './project-header.state';
import type { ProjectHeaderProps } from './project-header';
import { formatDate, parseDateRange } from '@/helpers/format-date';
import { useMemo } from 'react';
import { Inline } from '@/ui/line/line';
import { styled } from 'goober';
import { useFormContext } from 'react-hook-form';
import type { ProjectHeaderForm } from './edit/use-project-header-form';
import { useProject } from '../use-project.query';
import { CreatedForSelect } from './edit/created-for-select';
import { StatusSelect } from './edit/status-select';
import { TimeFramePicker } from './edit/timeframe-picker';

type ProjectHeaderLeftBlockProps = ProjectHeaderProps;

export const ProjectHeaderLeftBlock = ({
  isLoading,
  isCreateNewMode = false,
  onEditSubmit,
}: ProjectHeaderLeftBlockProps) => {
  const { data: project } = useProject();
  const {
    handleSubmit,
    formState: { isDirty, isValid },
  } = useFormContext<ProjectHeaderForm>();
  const setIsHovered = useSetRecoilState(isProjectHeaderHovered);
  const [isEditMode, setIsEditMode] = useRecoilState(isProjectHeaderEditMode);

  const detailsContent = useMemo(() => {
    const { team, creator } = project ?? {};

    return [
      {
        title: 'Created for',
        value: team?.name ?? '--',
        isEditable: true,
        editModeComponent: <CreatedForSelect />,
      },
      {
        title: 'Stage',
        value: project?.mostAdvancedListingStatus ?? '--',
      },
      {
        title: 'Created by',
        value: creator ? `${creator.firstName} ${creator.lastName}` : '--',
      },
      {
        title: 'Creation date',
        value: project?.createdDate
          ? formatDate(project.createdDate, { format: 'MM yyyy' })
          : '--',
      },
      {
        title: 'Timeframe',
        value:
          project?.timeframeStart && project?.timeframeEnd
            ? parseDateRange(project.timeframeStart, project.timeframeEnd)
            : '--',
        isEditable: true,
        editModeComponent: <TimeFramePicker />,
      },
    ];
  }, [project]);

  const handleOutsideClick = async () => {
    if (isCreateNewMode) {
      return await handleSubmit(onEditSubmit)();
    }

    if (isDirty && isValid) {
      await handleSubmit(onEditSubmit)();
    }

    setIsEditMode(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
      <Flexbox
        direction="column"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isLoading ? (
          <ProjectHeaderSkeleton />
        ) : (
          <>
            <ProjectHeaderFirstRow isCreateNewMode={isCreateNewMode} />
            {isEditMode ? (
              <StatusSelect />
            ) : (
              project?.status && <StatusIndicator status={project?.status} />
            )}

            <ContentBox
              justifyContent="flex-start"
              alignItems={isEditMode ? 'self-end' : 'flex-start'}
            >
              {detailsContent?.map(contentBlock => (
                <DetailBlock key={contentBlock.title} {...contentBlock} />
              ))}
            </ContentBox>
          </>
        )}
      </Flexbox>
    </OutsideClickHandler>
  );
};

const ContentBox = styled(Inline)`
  margin-top: 24px;
  gap: 20px;

  @media (min-width: 1440px) {
    gap: 50px;
  }
`;
