import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import type { SwitchOption } from '@/components/switch-mode-picker/switch-mode-picker';
import { SwitchModePicker } from '@/components/switch-mode-picker/switch-mode-picker';
import { ListingStageName } from '@/features/listing-stage/types';
import { modalState } from '@/state/modal.state';
import { Button } from '@/ui/button/button';
import { Inline } from '@/ui/line/line';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { MinimizedHeader } from '@/ui/typography/across-platform';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { useEnhanceButton } from '../enhance/use-enhance-button';
import type { ProjectListingsDisplayMode } from '../project-listings/project-listings';

import { useListingStagesToSwitchTabs } from './stage-management-table/use-listing-stages-to-switch-tabs';
import { projectIdState } from '../project.state';

export const ProjectListingsHeader = ({
  displayMode,
  toggleDisplayMode,
}: {
  displayMode: ProjectListingsDisplayMode;
  toggleDisplayMode: () => void;
}) => {
  const projectId = useRecoilValue(projectIdState);
  const { t } = useTranslation('projects');
  const { hasRequiredPermission } = useUserPermissions();
  const setModalState = useSetRecoilState(modalState);
  const { setActiveTabByStageName } = useListingStagesToSwitchTabs();
  const hasEditPermission =
    hasRequiredPermission(PERMISSION.ADD_COMPANIES_TO_PROJECTS) &&
    Boolean(projectId);

  const {
    hasPermissionToEnhanceProject,
    isEnhanceButtonEnabled,
    onEnhanceClick,
  } = useEnhanceButton(projectId);

  const openAddCompany = () => {
    if (!projectId) return;
    setModalState({
      state: 'addCompany',
      projectId,
      onSuccess: () => setActiveTabByStageName(ListingStageName.discovery),
    });
  };

  const displayModeConfig: Record<ProjectListingsDisplayMode, SwitchOption> =
    useMemo(
      () => ({
        table: {
          label: t`listings.table`,
          isOn: displayMode === 'table',
          icon: 'Table',
        },
        byStage: {
          label: t`listings.byStage`,
          isOn: displayMode === 'byStage',
          icon: 'ListBullets',
        },
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [displayMode],
    );

  return (
    <Inline data-testid="project-listings-header">
      <Inline justifyContent="flex-start" gap="36px">
        <TableHeader>{t`projectOverview.companiesInProjectHeader`}</TableHeader>
        <SwitchModePicker
          switchOptions={Object.values(displayModeConfig)}
          toggleMode={toggleDisplayMode}
        />
      </Inline>

      <Inline justifyContent="end">
        {hasPermissionToEnhanceProject && (
          <Tooltip
            content={t`enhanceDisabledText`}
            visible={!isEnhanceButtonEnabled}
          >
            <Button
              data-testid="project-listing-enhance-button"
              onClick={onEnhanceClick}
              startIcon="Sparkle"
              variant="primary"
              disabled={!isEnhanceButtonEnabled}
            >
              {t`enhance`}
            </Button>
          </Tooltip>
        )}
        {hasEditPermission && (
          <Button onClick={openAddCompany} startIcon="Plus" variant="secondary">
            {t`Add company`}
          </Button>
        )}
      </Inline>
    </Inline>
  );
};

const TableHeader = styled(MinimizedHeader)`
  padding: 10px 0;
`;
