import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import type { ListingStage } from '@/api/v4/project-listings.api';
import { ListingStageName } from '@/features/listing-stage/types';

import { getDealListings } from '../../helpers/get-deal-listings';
import { getListingsByStage } from '../../helpers/get-listings-by-stage';
import { projectIdState } from '../../project.state';
import { useProjectListings } from '../use-project-listings.query';

export const useTabProjectListings = (stageTab: ListingStage) => {
  const projectId = useRecoilValue(projectIdState);
  const { projectListings } = useProjectListings(projectId);

  return useMemo(() => {
    if (stageTab.name === ListingStageName.deal) {
      return getDealListings(projectListings);
    }
    return getListingsByStage(stageTab, projectListings);
  }, [projectListings, stageTab]);
};
