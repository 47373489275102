import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import websiteDisabled from '@/assets/icons/arrow-left-2.svg';
import SavvyBetaLogo from '@/assets/svg/ai-enhancement-savvy-beta.svg';
import { useProject } from '@/features/projects/overview/use-project.query';
import { Button } from '@/ui/button/button';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { Modal as ModalComponent } from '@/ui/modal/modal';
import { PRODUCT } from '@/user/products/product.type';
import { useUserProducts } from '@/user/products/use-user-products';

import {
  EnhanceProjectConfigStep1,
  TEXTAREA_MAX_LENGTH,
} from './config/enhance-project-config-step1';
import { EnhanceProjectConfigStep2 } from './config/enhance-project-config-step2';
import { EnhanceProjectResults } from './results/enhance-project-results';
import { useProjectListingSuggestionState } from './use-project-listing-suggestion.state';
import { convertFromRaw } from 'draft-js';
import { isJSON } from '@/helpers/is-json';

export const ProjectListingSuggestionFormModal = () => {
  const { t } = useTranslation(['projects', 'default']);
  const { hasRequiredProduct } = useUserProducts();
  const state = useProjectListingSuggestionState();
  const { data } = useProject();

  useEffect(() => {
    state.setDesiredSolution('');
    state.setLocations([]);
    state.setCompanyFilterSort({ direction: 'desc', sort: 'status' });
    return () => {
      state.setCurrentPageStep('config1');
    };
  }, []);

  useEffect(() => {
    if (!data || state.problemStatement) return;

    if (data) {
      const problemStatement = data?.problemStatement ?? '';
      const plainText = getPlainText(problemStatement);

      state.setProblemStatement(plainText);
    }
  }, [data, state]);

  useEffect(() => {
    if (Boolean(state.companyFilterSelected?.length)) {
      return;
    }
    if (state.projectListings?.length > 0) {
      state.setCompanyFilterSelectAll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.projectListings]);

  if (!data || !hasRequiredProduct(PRODUCT.ENHANCE_PROJECT)) return null;

  const requiredFieldsCompleted = (): boolean => {
    return (
      state.problemStatement.length > 0 &&
      state.problemStatement.length <= TEXTAREA_MAX_LENGTH
    );
  };

  const EnhanceHeader = () => (
    <HeaderTitle>
      {state.currentPageStep === 'config2' && (
        <BackIcon
          src={websiteDisabled}
          onClick={() => {
            if (state.currentPageStep === 'config2')
              state.setCurrentPageStep('config1');
            else if (state.currentPageStep === 'results')
              state.setCurrentPageStep('config2');
          }}
        />
      )}
      {state.currentPageStep !== 'error' && t`enhanceConfigHeader`}
    </HeaderTitle>
  );

  const handleNavigateButtonClick = () => {
    switch (state.currentPageStep) {
      case 'config1':
        state.setCurrentPageStep('config2');
        break;
      case 'config2':
      case 'error':
        state.setCurrentPageStep('analyzing');
        break;
      case 'results':
        state.setCurrentPageStep('config1');
    }
  };

  let navButtonText;

  switch (state.currentPageStep) {
    case 'config1':
      navButtonText = t`enhanceConfigNext`;
      break;
    case 'config2':
      navButtonText = t`enhanceConfigEnhance`;
      break;
    case 'error':
      navButtonText = t`enhanceRetry`;
      break;
    default:
      navButtonText = t`enhanceRegenerate`;
  }

  return (
    <Modal
      CustomHeaderComponent={
        state.currentPageStep !== 'analyzing' ? EnhanceHeader : undefined
      }
      header={
        state.currentPageStep !== 'analyzing'
          ? t`enhanceConfigHeader`
          : undefined
      }
      outsideClose={false}
    >
      <Stack>
        {state.currentPageStep === 'config1' ? (
          <EnhanceProjectConfigStep1 />
        ) : state.currentPageStep === 'config2' ? (
          <EnhanceProjectConfigStep2 />
        ) : ['analyzing', 'error', 'results'].includes(
            state.currentPageStep,
          ) ? (
          <EnhanceProjectResults />
        ) : (
          <>{state.currentPageStep}</>
        )}
        <Flexbox
          name="enhance-config-footer"
          justify="space-between"
          alignItems="center"
          padding="30px 0px"
        >
          <img height="38px" src={SavvyBetaLogo} alt="savvy-beta-logo" />
          {state.currentPageStep !== 'analyzing' && (
            <>
              {(state.currentPageStep === 'config1' ||
                state.currentPageStep === 'config2') && (
                <StepText>
                  {state.currentPageStep === 'config1'
                    ? t`enhanceConfigStage1of2`
                    : t`enhanceConfigStage2of2`}
                </StepText>
              )}
              <Button
                data-testid="project-listing-suggestion-next-button"
                contentPadding="lg"
                disabled={!requiredFieldsCompleted()}
                onClick={handleNavigateButtonClick}
              >
                {navButtonText}
              </Button>
            </>
          )}
        </Flexbox>
      </Stack>
    </Modal>
  );
};

const Modal = styled(ModalComponent)`
  padding: 38px 50px 0px 50px;
  min-width: 987px;
  max-width: 987px;
  min-height: 648px;
  justify-content: space-between;
`;

const BackIcon = styled('img')`
  cursor: pointer;
`;

const HeaderTitle = styled('h1')`
  ${({ theme }) => theme.typography.widget.emptyState}
  display: flex;
`;

const StepText = styled('p')`
  ${({ theme }) => theme.typography.widget.smallText}
`;

export const OverflowContainer = styled(Flexbox)`
  overflow-y: auto;
  margin-top: 18px;
  padding-right: 10px;
  ${({ theme }) => theme.mixins.scrollbar}
`;

const getPlainText = (content: string): string => {
  if (isJSON(content)) {
    const parsed = JSON.parse(content);
    const contentState = convertFromRaw(parsed);
    return contentState.getPlainText();
  }
  return content;
};
