import { useTranslation } from 'react-i18next';

import { colors, componentsPalette } from '@/theme/colors';
import type { AsyncSelectProps } from '@/ui/select/async/async-select';
import { AsyncSelect } from '@/ui/select/async/async-select';
import type { SelectCustomSharedProps } from '@/ui/select/select-shared';
import { getCustomSharedProps } from '@/ui/select/select-shared';

type FormSelectProps = AsyncSelectProps & SelectCustomSharedProps;

export const FormSelect = ({ ...props }: FormSelectProps) => {
  const { t } = useTranslation('default');
  const { components, styles } = getCustomSharedProps({
    dropdownIndicatorColor: colors.gray.c10,
    placeholderColor: colors.gray.c10,
    menuListHeight: '280px',
    backgroundColor: componentsPalette.explore.form.background,
    ...props,
  });

  return (
    <AsyncSelect
      placeholder={t`select`}
      isClearable
      isSearchable
      components={components}
      styles={styles}
      menuPlacement="auto"
      {...props}
    />
  );
};
