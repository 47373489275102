import { useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { modalState } from '@/state/modal.state';
import { APP_USER_ROLES } from '@/types';
import { PRODUCT } from '@/user/products/product.type';
import { useUserProducts } from '@/user/products/use-user-products';
import { useUserRole } from '@/user/use-user-role';

import { useProjectListingSuggestionState } from '../../modals/enhance-project-modals/use-project-listing-suggestion.state';
import { projectListingSortState } from '../project-listing.state';
import { useProjectListings } from '../project-listings/use-project-listings.query';

export const useEnhanceButton = (projectId: string | null) => {
  const state = useProjectListingSuggestionState();
  const userRole = useUserRole();
  const { hasRequiredProduct } = useUserProducts();
  const sort = useRecoilValue(projectListingSortState);
  const { projectListings } = useProjectListings(projectId, sort);
  const setModalState = useSetRecoilState(modalState);

  const hasPermissionToEnhanceProject = useMemo(() => {
    return (
      userRole !== APP_USER_ROLES.GUEST &&
      hasRequiredProduct(PRODUCT.ENHANCE_PROJECT)
    );
  }, [hasRequiredProduct, userRole]);

  const isEnhanceButtonEnabled = useMemo(() => {
    return projectListings.length >= 3;
  }, [projectListings.length]);

  const onEnhanceClick = useCallback(() => {
    if (!state.hasAcceptedTermsOfUse) {
      return setModalState({ state: 'enhanceProjectIntro' });
    }

    return setModalState({ state: 'enhanceProjectForm' });
  }, [state.hasAcceptedTermsOfUse, setModalState]);

  return {
    hasPermissionToEnhanceProject,
    isEnhanceButtonEnabled,
    onEnhanceClick,
  };
};
