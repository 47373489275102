import type { User } from '@/api/v4/user.api';
import type { UserOption } from '@/types';

export const mapUserToOption = (user: User): UserOption => ({
  label:
    user.firstName && user.lastName
      ? user.firstName + ' ' + user.lastName
      : user.email,
  value: user.id,
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  team: user.team,
  role: user.role,
  status: user.status,
});
