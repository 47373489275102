import type { MouseEvent } from 'react';
import { forwardRef, useMemo } from 'react';
import { styled } from 'goober';

import type {
  ProjectCompaniesSort,
  ProjectListing,
} from '@/api/v4/projects.api';
import { SuspenseFallback } from '@/components/suspense-fallback/suspense-fallback';
import { getListingStageName } from '@/features/listing-stage/helpers/get-listing-stage-name';
import { useListingStages } from '@/features/listing-stage/use-listing-stages.query';
import { formatEmpty, formatNotApplicable } from '@/helpers/format-empty';
import { formatLocation } from '@/helpers/format-location';
import { getAbbreviateNumber } from '@/helpers/format-number';
import { openNewTab } from '@/helpers/open-new-tab';
import { trimText } from '@/helpers/text-helper';
import { colors } from '@/theme/colors';
import { theme } from '@/theme/setupTheme';
import { Button } from '@/ui/button/button';
import { IconButton } from '@/ui/button/icon-button';
import { WithDropdown } from '@/ui/dropdown/dropdown';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { Stack } from '@/ui/line/line';
import { Logo } from '@/ui/logo/logo';
import { useTranslateHeaders } from '@/ui/table/helpers/use-translate-headers';
import type { Row } from '@/ui/table/table.types';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { SmallText } from '@/ui/typography/widgets';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { ListingMenu } from '../stage-management-table/table/listing-menu';
import { Rating } from '../stage-management-table/table/ranking';

import { generateListingStageMenu } from './generate-listing-stage-menu';
import { ProjectListingTableHeader } from './project-listing-table-header-config';
import { useTranslation } from 'react-i18next';
import { paths } from '@/routes/helpers/paths';
import { Link } from '@/ui/link/link';

const STAGE_BUTTON_WIDTH = 160;

const openCompanyWebsiteTab = (url: string) => (event: MouseEvent) => {
  event.stopPropagation();
  openNewTab(url);
};

type UseProjectCompanyTableConfigurationProps = {
  data: ProjectListing[];
  onListingStatusChanged: (
    listingId: string,
    newListStatusId: string | null,
    isDeal?: boolean,
  ) => void;
};

export const useProjectListingsTableConfiguration = ({
  data,
  onListingStatusChanged,
}: UseProjectCompanyTableConfigurationProps) => {
  const headers = useTranslateHeaders<ProjectCompaniesSort>(
    ProjectListingTableHeader,
    'companies',
    'companies',
  );
  const { t } = useTranslation('companies');

  const { hasRequiredPermission } = useUserPermissions();
  const { listingStages } = useListingStages();

  const canChangeCompanyStage = hasRequiredPermission(
    PERMISSION.UPDATE_COMPANY_LISTING_STATUS,
  );

  const rows = useMemo<Row[]>(
    () => {
      if (!listingStages?.length) {
        return [];
      }

      return data.map(listing => {
        const rowBackgroundColor = listing.notRelevant
          ? theme.colors.gray.c1
          : undefined;

        const listingStatusMenu = listingStages?.length
          ? generateListingStageMenu({
              listingId: listing.id,
              status: listing.status,
              isNotRelevant: listing.notRelevant,
              onListingStatusChanged,
              listingStages,
            })
          : [];

        return {
          id: listing.organization.id,
          rowPadding: {
            left: '60px',
            right: '0px',
          },
          rowBackgroundColor,
          rowHover: true,
          cells: [
            {
              value: (
                <CompanyNameTableCell
                  listing={listing}
                  shouldLinkToCompany={hasRequiredPermission(
                    PERMISSION.VIEW_ORGANIZATION_HOME_SCREEN,
                  )}
                />
              ),
            },
            {
              value: (
                <RowContainer
                  isNotRelevant={listing.notRelevant}
                  useSecondaryColor
                  name="company-funding-stage"
                  justify="start"
                >
                  {formatNotApplicable(listing.organization.fundingStage?.name)}
                </RowContainer>
              ),
            },
            {
              value: (
                <RowContainer
                  isNotRelevant={listing.notRelevant}
                  useSecondaryColor
                  name="company-capital-raised"
                  justify="start"
                >
                  {listing.organization.capitalRaised
                    ? getAbbreviateNumber(
                        listing.organization.capitalRaised.toString(),
                      )
                    : formatNotApplicable(listing.organization.capitalRaised)}
                </RowContainer>
              ),
            },
            {
              value: (
                <RowContainer
                  isNotRelevant={listing.notRelevant}
                  useSecondaryColor
                  name="company-year-founded"
                  justify="start"
                >
                  {listing.organization.yearEstablished}
                </RowContainer>
              ),
            },
            {
              value: (
                <RowContainer name="company-rating" justify="start">
                  <Rating ranking={listing.organization.averageRating} />
                </RowContainer>
              ),
            },
            {
              value: (
                <RowContainer
                  name="company-listing-stage"
                  dataTestId="company-listing-stage"
                  justify="left"
                  padding="0px 12px"
                >
                  <WithDropdown
                    disabled={
                      !hasRequiredPermission(
                        PERMISSION.UPDATE_COMPANY_LISTING_STATUS,
                      )
                    }
                    testId={`listing-row-company-stage-menu-${listing.organization.id}`}
                    items={listingStatusMenu}
                    options={{ placement: 'bottom-end' }}
                    maxWidth="auto"
                  >
                    <CompanyStageButton
                      variant={listing.notRelevant ? 'gray' : 'purple'}
                      endIcon={canChangeCompanyStage ? 'DownSm' : undefined}
                      width={STAGE_BUTTON_WIDTH}
                    >
                      {listing.notRelevant ? (
                        t`listingStatuses.notRelevant`
                      ) : (
                        <EllipsisText maxWidth={`${STAGE_BUTTON_WIDTH}px`}>
                          {getListingStageName(listing.status, listingStages)}
                        </EllipsisText>
                      )}
                    </CompanyStageButton>
                  </WithDropdown>
                </RowContainer>
              ),
            },
            {
              value: (
                <SuspenseFallback>
                  <ListingMenu
                    key={listing.id}
                    listing={listing}
                    blacklist={['moveToStage']}
                  />
                </SuspenseFallback>
              ),
              hoverOnly: true,
              align: 'right',
              verticalAlign: 'middle',
              padding: '0',
            },
          ],
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, listingStages],
  );

  return { rows, headers };
};

const CompanyNameTableCell = ({
  listing,
  shouldLinkToCompany,
}: {
  listing: ProjectListing;
  shouldLinkToCompany: boolean;
}) => {
  const hqLocation = listing.organization.hqLocation
    ? {
        cityName: listing.organization.hqLocation.city?.name,
        regionName: listing.organization.hqLocation.region?.name,
        countryName: listing.organization.hqLocation.region?.name,
      }
    : null;
  return (
    <Flexbox name="company-name-container" gap="8px" alignItems="center">
      <Logo
        singleLetter
        name={listing.organization.name}
        logoUrl={listing.organization.logoUrl ?? undefined}
        bgColor={colors.accent.green.c3}
        border={
          listing.organization.logoUrl
            ? `2px solid ${colors.gray.c3}`
            : undefined
        }
      />
      <Stack gap="3px">
        <Flexbox name="discovery-company-name" alignItems="center" gap="2px">
          <Tooltip
            content={trimText(
              formatNotApplicable(listing.organization.keyOffering),
              120,
              '...',
            )}
            maxWidth="500px"
            placement="top-start"
          >
            {shouldLinkToCompany ? (
              <Link
                to={paths.company({ companyId: listing.organization.id })}
                color={theme.colors.basics.black}
              >
                {listing.organization.name}
              </Link>
            ) : (
              <CompanyName>{listing.organization.name}</CompanyName>
            )}
          </Tooltip>
          {!!listing.organization.website && (
            <Tooltip
              content={listing.organization.website}
              placement="top"
              maxWidth="fit-content"
            >
              <IconButton
                icon="Globe"
                variant="ghost"
                color={colors.basics.black}
                onClick={openCompanyWebsiteTab(listing.organization.website)}
              />
            </Tooltip>
          )}
        </Flexbox>

        <Tooltip
          disabled={!listing.organization.hqLocation?.city}
          content={formatEmpty(hqLocation && formatLocation(hqLocation))}
        >
          <Location isNotRelevant={listing.notRelevant}>
            {formatEmpty(
              hqLocation &&
                formatLocation(hqLocation, {
                  compact: true,
                }),
            )}
          </Location>
        </Tooltip>
      </Stack>
    </Flexbox>
  );
};

const RowContainer = styled(Flexbox)<{
  isNotRelevant?: boolean;
  useSecondaryColor?: boolean;
}>`
  ${({ isNotRelevant, useSecondaryColor, theme }) =>
    `color: ${
      isNotRelevant
        ? theme.colors.gray.c7
        : useSecondaryColor
        ? colors.gray.c12
        : undefined
    }`};
`;

const EllipsisText = styled('p', forwardRef)<{ maxWidth: string }>`
  ${({ theme }) => theme.mixins.ellipsis}
  max-width: ${({ maxWidth }) => maxWidth};
`;

const CompanyName = styled('p', forwardRef)`
  max-width: 173px;
  min-height: 20px;
  ${({ theme }) => theme.mixins.ellipsis};
`;

const Location = styled(SmallText, forwardRef)<{ isNotRelevant?: boolean }>`
  max-width: 173px;
  ${({ isNotRelevant, theme }) =>
    `color: ${isNotRelevant ? theme.colors.gray.c7 : undefined}`};
  ${({ theme }) => theme.mixins.ellipsis};
`;

const CompanyStageButton = styled(Button)`
  border-radius: 4px;
  padding: 7px 8px;
`;
