import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

import { ActionButton } from '@/components/actions-button/actions-button';
import { useActionPanel } from '@/features/action-panel/use-action-panel';
import { AskXButton } from '@/features/ask-x/ask-x-button';
import { useProjectActionsInfo } from '@/features/projects/action-panel/use-project-actions-info.query';
import { useExportHandlers } from '@/features/projects/hooks/use-export-handlers';
import { useProject } from '@/features/projects/overview/use-project.query';
import { modalState } from '@/state/modal.state';
import { Button } from '@/ui/button/button';
import { IconButton } from '@/ui/button/icon-button';
import type { DropdownOption } from '@/ui/dropdown/dropdown';
import { WithDropdown } from '@/ui/dropdown/dropdown';
import { Inline } from '@/ui/line/line';
import { MenuButton } from '@/ui/menu-button/menu-button';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import { PRODUCT } from '@/user/products/product.type';
import { useUserProducts } from '@/user/products/use-user-products';
import { useProjectSidebar } from '@/features/projects/overview/sidebar/use-project-sidebar';

export const ProjectHeaderActions = () => {
  const { t } = useTranslation('projects');
  const { data: projectActions } = useProjectActionsInfo();
  const { hasRequiredPermission } = useUserPermissions();
  const { hasRequiredProduct } = useUserProducts();
  const { handleChangeToggleProjectSidebar } = useProjectSidebar();
  const { data: project } = useProject();
  const setModalState = useSetRecoilState(modalState);
  const { handleStreamExportToXLS, handleExportToPpt } = useExportHandlers();
  const { toggleActionPanel } = useActionPanel();

  if (!project) return null;

  const onToggleActionPanelClick = () =>
    toggleActionPanel({ context: 'project', title: project.name });

  const openShareProject = () => {
    setModalState({ state: 'shareProject' });
  };

  const hasPermissionToShareProject =
    hasRequiredPermission(PERMISSION.SHARE_A_STREAM_TO_EXISTING_Q_USERS) ||
    project.isOwnedByUser;

  const menu: DropdownOption[] = [
    {
      label: t`share`,
      isVisible: hasPermissionToShareProject,
      onClick: openShareProject,
      icon: 'ArrowUpFromSquare',
    },
    {
      label: t`duplicate`,
      isVisible: hasRequiredPermission(PERMISSION.DUPLICATE_STREAM),
      onClick: () => setModalState({ state: 'duplicateProject' }),
      icon: 'Copy',
    },
    {
      label: t`copyToAccount.menuItemLabel`,
      isVisible: hasRequiredPermission(PERMISSION.DUPLICATE_STREAM),
      onClick: () =>
        setModalState({
          state: 'copyProjectModal',
          projectId: project.id,
        }),
      icon: 'CopyTo',
    },
    {
      label: t`loadCompaniesFromExcel`,
      isVisible: hasRequiredPermission(PERMISSION.ADD_COMPANIES_TO_PROJECTS),
      onClick: () =>
        setModalState({
          state: 'loadCompaniesToProject',
          projectId: project.id,
        }),
    },
    {
      label: t`exportToCsv`,
      isVisible:
        hasRequiredProduct(PRODUCT.EXPORT_STREAM) &&
        hasRequiredPermission(PERMISSION.EXPORT_PROJECT),
      onClick: handleStreamExportToXLS,
      isDisabled: project.listingsCount === 0,
      isTooltipVisible: project.listingsCount === 0,
      tooltipContent: t`addCompaniesToExport`,
    },
    {
      label: t`exportToPpt`,
      isVisible: hasRequiredProduct(PRODUCT.EXPORT_TO_PPT),
      onClick: handleExportToPpt,
    },
    {
      label: t`deleteProject`,
      isVisible: hasRequiredPermission(PERMISSION.DELETE_PROJECT),
      onClick: () =>
        setModalState({ state: 'deleteProject', projectId: project.id }),
      icon: 'Bin',
    },
  ];

  return (
    <Inline alignItems="center" gap="20px">
      <AskXButton />

      {hasPermissionToShareProject && (
        <IconButton
          dataTestId="share-project-button"
          onClick={openShareProject}
          icon="ArrowUpFromSquare"
          variant="ghost"
          size="16px"
        />
      )}
      <ActionButton
        totalValue={projectActions?.total || 0}
        onClick={onToggleActionPanelClick}
      />
      <Button onClick={handleChangeToggleProjectSidebar}>{t`moreInfo`}</Button>
      <WithDropdown items={menu} options={{ placement: 'bottom-end' }}>
        <Button variant="text">
          <MenuButton />
        </Button>
      </WithDropdown>
    </Inline>
  );
};
