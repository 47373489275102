import { useRecoilValue } from 'recoil';

import { getListingStageByDefaultName } from '@/features/listing-stage/helpers/get-listing-stage-by-default-name';
import { useListingStages } from '@/features/listing-stage/use-listing-stages.query';

import { isDealStage, ListingStageName } from '../../../listing-stage/types';
import { projectIdState } from '../../project.state';
import { useProjectListings } from '../use-project-listings.query';

import { type TabStage, useStageTabsConfig } from './use-stage-tabs-config';
import { useSearchParams } from 'react-router-dom';

export const useListingStagesToSwitchTabs = () => {
  const projectId = useRecoilValue(projectIdState);
  const { projectListings } = useProjectListings(projectId);
  const { listingStages } = useListingStages();
  const [, setSearchParams] = useSearchParams();
  const stageTabs = useStageTabsConfig();

  const getDropToDestination = (currentTab: TabStage) => {
    if (
      currentTab.name === ListingStageName.notRelevant ||
      currentTab.name === ListingStageName.deal
    ) {
      return;
    }

    if (currentTab.name === ListingStageName.pilot) {
      return {
        name: 'Implementation',
        id: '',
        displayName: null,
        sortOrder: 0,
      };
    }

    const nextStage = stageTabs?.find(
      stage => stage.sortOrder === currentTab.sortOrder + 1,
    );

    return nextStage;
  };

  const getFirstStageWithListings = (): TabStage | undefined => {
    const tab = stageTabs?.find(tab => isAnyCompanyAtStage(tab));

    return tab;
  };

  const isAnyCompanyAtStage = (tabStage: TabStage) => {
    if (tabStage.name === ListingStageName.notRelevant) {
      return projectListings.some(listing => listing.notRelevant);
    }

    if (tabStage.name === ListingStageName.deal) {
      return projectListings.some(
        listing => isDealStage(listing.status.name) && !listing.notRelevant,
      );
    }
    return projectListings.some(
      listing => listing.status.id === tabStage.id && !listing.notRelevant,
    );
  };

  const setActiveTabByStageName = (stageName: string) => {
    const stage = getListingStageByDefaultName(stageName, listingStages);

    if (!stage) {
      throw new Error(`${stageName} - Project listing stage not found`);
    }

    setSearchParams(searchParams => {
      searchParams.set(
        'stage',
        isDealStage(stage.name) ? ListingStageName.deal : stage.id,
      );

      return searchParams;
    });
  };

  const setActiveFirstTabWithListings = () => {
    const firstStageWithListings = getFirstStageWithListings();
    if (!firstStageWithListings) {
      return;
    }
    setSearchParams(searchParams => {
      searchParams.set('stage', firstStageWithListings.id);

      return searchParams;
    });
  };

  return {
    getDropToDestination,
    isAnyCompanyAtStage,
    setActiveTabByStageName,
    setActiveFirstTabWithListings,
  };
};
