import { useTranslation } from 'react-i18next';
import { styled } from 'goober';

import { OverviewPageContainer } from '@/components/layout/overview-page-container';
import { WithHtmlSavvyTitle } from '@/with-html-savvy-title';

import { ProjectHeader } from './overview/header/project-header';
import { useCreateProject } from './use-create-project.mutation';
import { generateUntitledEntityName } from '@/helpers/generate-untitled-entity-name';
import { useNavigateTo } from '@/routes/hooks/use-navigate-to';
import {
  useProjectHeaderForm,
  type ProjectHeaderForm,
} from './overview/header/edit/use-project-header-form';
import { useEffect } from 'react';
import { projectIdState } from './project.state';
import { useResetRecoilState } from 'recoil';
import { getEditFormDefaultValues } from './overview/header/helpers';
import { FormProvider } from 'react-hook-form';
import { ProjectListings } from './project-listings/project-listings';

export const NewProjectPage = () => {
  const { t } = useTranslation('projects');
  const { createProject, isCreating } = useCreateProject();
  const resetProjectId = useResetRecoilState(projectIdState);
  const navigateTo = useNavigateTo();
  const methods = useProjectHeaderForm(getEditFormDefaultValues(), true);

  useEffect(() => {
    resetProjectId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateProject = async (values: ProjectHeaderForm) => {
    const name = !values.name
      ? generateUntitledEntityName('project')
      : values.name;

    const data = await createProject({ name });
    navigateTo.project({ projectId: data.id });
  };

  return (
    <WithHtmlSavvyTitle title={t`projects.project`}>
      <Relative>
        <OverviewPageContainer
          name="new-project-page-container"
          direction="column"
          justify="space-between"
        >
          <FormProvider {...methods}>
            <ProjectHeader
              isCreateNewMode
              isLoading={isCreating}
              onEditSubmit={handleCreateProject}
            />
          </FormProvider>
          <ProjectListings isProjectLoading={false} isDisabledMode />
        </OverviewPageContainer>
      </Relative>
    </WithHtmlSavvyTitle>
  );
};

const Relative = styled('div')`
  position: relative;
`;
