import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import type { EnhanceProjectListingSuggestion } from '@/api/v4/project-enhance-listing-suggestions.api';
import type { IOption } from '@/types';
import type { IPageState, ISort } from '@/ui/table/table.types';

import { projectIdState } from '../../projects/project.state';
import { modalState } from '@/state/modal.state';
import { useProjectListings } from '@/features/projects/project-listings/use-project-listings.query';
import type { ProjectCompaniesSort } from '@/api/v4/projects.api';

const { persistAtom } = recoilPersist();
type PageSteps = 'config1' | 'config2' | 'analyzing' | 'results' | 'error';

const pageStepState = atom<PageSteps>({
  key: 'projectListingSuggestionPageStepState',
  default: 'config1',
});

const termsOfUseAcceptanceState = atom<boolean>({
  key: 'projectListingSuggestionTermsOfUseAcceptanceState',
  default: false,
  effects: [persistAtom],
});

const problemStatementState = atom<string>({
  key: 'projectListingSuggestionProblemState',
  default: '',
});

const desiredSolutionState = atom<string>({
  key: 'projectListingSuggestionDesiredSolutionState',
  default: '',
});

const locationsState = atom<IOption[]>({
  key: 'projectListingSuggestionLocationState',
  default: [],
});

const companyFilterPageState = atom<IPageState>({
  key: 'projectListingSuggestionCompanyFilterPageState',
  default: { page: 1, pageSize: 999999 },
});

const companyFilterSortState = atom<ISort<ProjectCompaniesSort>>({
  key: 'projectListingSuggestionCompanyFilterSortState',
  default: { direction: 'desc', sort: 'status' },
});

const companyFilterSelectedListState = atom<string[]>({
  key: 'projectListingSuggestionCompanySelectedState',
  default: [],
});

const addedSuggestionsState = atom<EnhanceProjectListingSuggestion[]>({
  key: 'projectListingSuggestionAddedState',
  default: [],
});

const rejectedSuggestionsState = atom<EnhanceProjectListingSuggestion[]>({
  key: 'projectListingSuggestionRejectedState',
  default: [],
});

const inProgressSuggestionsState = atom<EnhanceProjectListingSuggestion[]>({
  key: 'projectListingSuggestionInProgressState',
  default: [],
});

export const useProjectListingSuggestionState = () => {
  const [modal, setModalState] = useRecoilState(modalState);

  const isModalMounted = modal?.state === 'enhanceProjectForm';

  const [hasAcceptedTermsOfUse, setTermsOfUseAcceptance] = useRecoilState(
    termsOfUseAcceptanceState,
  );

  const [currentPageStep, setCurrentPageStep] = useRecoilState(pageStepState);

  const projectId = useRecoilValue(projectIdState);

  const [companyFilterSort, setCompanyFilterSort] = useRecoilState(
    companyFilterSortState,
  );

  const { projectListings } = useProjectListings(
    projectId,
    companyFilterSort,
    isModalMounted,
  );

  const [companyFilterSelected, setCompanyFilterSelected] = useRecoilState(
    companyFilterSelectedListState,
  );

  const [problemStatement, setProblemStatement] = useRecoilState(
    problemStatementState,
  );

  const [desiredSolution, setDesiredSolution] =
    useRecoilState(desiredSolutionState);

  const [locations, setLocations] = useRecoilState(locationsState);

  const [addedSuggestions, setAddedSuggestions] = useRecoilState(
    addedSuggestionsState,
  );

  const [rejectedSuggestions, setRejectedSuggestions] = useRecoilState(
    rejectedSuggestionsState,
  );

  const [inProgressSuggestions, setInProgressSuggestions] = useRecoilState(
    inProgressSuggestionsState,
  );

  const acceptTermsOfUsage = () => {
    setTermsOfUseAcceptance(true);
  };

  const addSuggestionToInProgress = (
    suggestion: EnhanceProjectListingSuggestion,
  ) => {
    setInProgressSuggestions(arr => [...arr, suggestion]);
  };

  const addSuggestionToAdded = (
    suggestion: EnhanceProjectListingSuggestion,
  ) => {
    setAddedSuggestions(arr => [...arr, suggestion]);
  };

  const addSuggestionToRejected = (
    suggestion: EnhanceProjectListingSuggestion,
  ) => {
    setRejectedSuggestions(arr => [...arr, suggestion]);
  };

  const removeSuggestionFromInProgress = (
    suggestion: EnhanceProjectListingSuggestion,
  ) => {
    setInProgressSuggestions(arr =>
      arr.filter(s => s.permalink !== suggestion.permalink),
    );
  };

  const removeSuggestionFromRejected = (
    suggestion: EnhanceProjectListingSuggestion,
  ) => {
    setRejectedSuggestions(arr =>
      arr.filter(s => s.permalink !== suggestion.permalink),
    );
  };

  const setCompanyFilterSelectAll = (selected: boolean) => {
    setCompanyFilterSelected(
      projectListings && selected
        ? projectListings.map(listing => listing.organization.id)
        : [],
    );
  };

  const toggleCompanyFilterSelected = (organizationId: string) => {
    setCompanyFilterSelected((old: string[]) => {
      if (old.includes(organizationId)) {
        return old.filter(item => item !== organizationId);
      }
      return [...old, organizationId];
    });
  };

  const isSuggestionAdded = (suggestion: EnhanceProjectListingSuggestion) => {
    return !!addedSuggestions.find(s => s.permalink === suggestion.permalink);
  };

  const isSuggestionInProgress = (
    suggestion: EnhanceProjectListingSuggestion,
  ) => {
    return !!inProgressSuggestions.find(
      s => s.permalink === suggestion.permalink,
    );
  };

  const isSuggestionRejected = (
    suggestion: EnhanceProjectListingSuggestion,
  ) => {
    return !!rejectedSuggestions.find(
      s => s.permalink === suggestion.permalink,
    );
  };

  const closeModal = () => {
    setModalState(null);
  };

  return {
    acceptTermsOfUsage,
    closeModal,
    companyFilterPageState,
    companyFilterSortState,
    currentPageStep,
    setCurrentPageStep,
    hasAcceptedTermsOfUse,
    projectId,
    projectListings,
    problemStatement,
    setProblemStatement,
    desiredSolution,
    setDesiredSolution,
    locations,
    setLocations,
    inProgressSuggestions,
    addSuggestionToAdded,
    addSuggestionToInProgress,
    addSuggestionToRejected,
    removeSuggestionFromInProgress,
    removeSuggestionFromRejected,
    companyFilterSelected,
    setCompanyFilterSelectAll,
    toggleCompanyFilterSelected,
    setCompanyFilterSort,
    isSuggestionAdded,
    isSuggestionInProgress,
    isSuggestionRejected,
  };
};
