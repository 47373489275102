import { SkeletonBox } from '@/components/skeleton/skeleton-box';
import { Stack } from '@/ui/line/line';

export const ProjectHeaderSkeleton = () => {
  return (
    <Stack>
      <SkeletonBox width="200px" height="20px" />
      <SkeletonBox width="40px" height="12px" />
      <SkeletonBox width="100px" height="20px" margin="20px 0 10px 0" />
      <SkeletonBox width="200px" height="20px" />
    </Stack>
  );
};
