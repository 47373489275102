import { isEllipsis } from '@/helpers/other';
import { Input } from '@/ui/input/input';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { styled } from 'goober';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { useFormContext } from 'react-hook-form';
import { isProjectHeaderEditMode } from '../project-header.state';
import type { ProjectHeaderForm } from './use-project-header-form';

interface ProjectNameInputProps {
  name: string;
}

const ProjectName = ({ name }: { name: string }) => {
  const ref = useRef<HTMLSpanElement>(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setIsEllipsisActive(isEllipsis(ref.current));
    }
  }, [name]);

  return (
    <TextWrapper content={name} visible={isEllipsisActive}>
      <Text ref={ref} data-testid="project-name-value">
        {name}
      </Text>
    </TextWrapper>
  );
};

export const ProjectNameInput = ({ name }: ProjectNameInputProps) => {
  const { t } = useTranslation('projects');
  const [isEditMode] = useRecoilState(isProjectHeaderEditMode);

  const {
    register,
    formState: { errors, defaultValues },
  } = useFormContext<ProjectHeaderForm>();

  const nameError = errors.name;

  if (name && !isEditMode) {
    return <ProjectName name={name} />;
  }

  return (
    <Input
      {...register('name')}
      data-testid="project-name-input"
      autoFocus
      placeholder={t('projectOverview.nameOfProject')}
      error={nameError?.message}
      defaultValue={defaultValues?.name ?? ''}
      fontSize="24px"
      errorFontSize="14px"
    />
  );
};

const Text = styled('span', forwardRef)`
  ${({ theme }) => theme.typography.widget.projectNameHeader}
  ${({ theme }) => theme.mixins.ellipsis}
`;

const TextWrapper = styled(Tooltip)`
  display: inline-grid;
`;
