export const shadow = {
  project: {
    header: '0px 0px 14px 0px rgba(0, 0, 0, 0.15)',
  },
  modal: `-21px 35px 25px rgba(87, 132, 152, 0.02),
    -10px 16px 18px rgba(87, 132, 152, 0.03),
    -2px 4px 10px rgba(87, 132, 152, 0.03), 0px 0px 0px rgba(87, 132, 152, 0.03);
  border-radius: 30px`,
  actionsPanel: `-29px 104px 43px rgba(68, 84, 111, 0.01), -16px 58px 36px rgba(68, 84, 111, 0.03), -7px 26px 27px rgba(68, 84, 111, 0.04), -2px 6px 15px rgba(68, 84, 111, 0.05), 0px 0px 0px rgba(68, 84, 111, 0.05)`,
  widgets: `-21px 35px 25px rgba(87, 132, 152, 0.02), -10px 16px 18px rgba(87, 132, 152, 0.03), -2px 4px 10px rgba(87, 132, 152, 0.03), 0px 0px 0px rgba(87, 132, 152, 0.03)`,
  authPage: {
    pinkBoxLeft: `-39.4959px 141.641px 58.5629px rgba(22, 48, 199, 0.01), -21.7908px 78.9918px 49.0294px rgba(22, 48, 199, 0.03), -9.53349px 35.4101px 36.772px rgba(22, 48, 199, 0.04), -2.72386px 8.17157px 20.4289px rgba(22, 48, 199, 0.05), 0px 0px 0px rgba(22, 48, 199, 0.05);`,
    pinkBoxMiddle: `-39.4959px 141.641px 58.5629px rgba(41, 69, 222, 0.01), -21.7908px 78.9918px 49.0294px rgba(41, 69, 222, 0.03), -9.53349px 35.4101px 36.772px rgba(41, 69, 222, 0.04), -2.72386px 8.17157px 20.4289px rgba(41, 69, 222, 0.05), 0px 0px 0px rgba(41, 69, 222, 0.05);`,
    pinkBoxRight: `-39.4959px 141.641px 58.5629px rgba(60, 89, 246, 0.01), -21.7908px 78.9918px 49.0294px rgba(60, 89, 246, 0.03), -9.53349px 35.4101px 36.772px rgba(60, 89, 246, 0.04), -2.72386px 8.17157px 20.4289px rgba(60, 89, 246, 0.05), 0px 0px 0px rgba(60, 89, 246, 0.05);`,
    locationBox: `-31.6196px 113.395px 46.8843px rgba(68, 84, 111, 0.01), -17.4453px 63.2392px 39.2519px rgba(68, 84, 111, 0.03), -7.63232px 28.3486px 29.439px rgba(68, 84, 111, 0.04), -2.18066px 6.54199px 16.355px rgba(68, 84, 111, 0.05), 0px 0px 0px rgba(68, 84, 111, 0.05);`,
  },
  companiesKPI: `-21px -35px 25px 0px rgba(87, 132, 152, 0.02)`,
  companySections:
    '0px 0px 0px 0px rgba(68, 84, 111, 0.05), -2px 6px 15px 0px rgba(68, 84, 111, 0.05), -7px 26px 27px 0px rgba(68, 84, 111, 0.04), -16px 58px 36px 0px rgba(68, 84, 111, 0.03), -29px 104px 43px 0px rgba(68, 84, 111, 0.01), -46px 162px 47px 0px rgba(68, 84, 111, 0.00);',
} as const;
