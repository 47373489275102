import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export type ActionItemType = 'comment' | 'meeting' | 'file';

export type ActionPanelSearchParamData = {
  id: string | null;
  type: ActionItemType;
};

export const useActionPanelSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const resetActionPanelSearchParams = () => {
    if (searchParams.has('comment')) {
      searchParams.delete('comment');
    }

    if (searchParams.has('meeting')) {
      searchParams.delete('meeting');
    }

    if (searchParams.has('file')) {
      searchParams.delete('file');
    }

    setSearchParams(searchParams);
  };

  const getActionPanelSearchParam =
    useCallback((): ActionPanelSearchParamData | null => {
      if (searchParams.has('comment')) {
        return { id: searchParams.get('comment'), type: 'comment' };
      }

      if (searchParams.has('meeting')) {
        return { id: searchParams.get('meeting'), type: 'meeting' };
      }

      if (searchParams.has('file')) {
        return { id: searchParams.get('file'), type: 'file' };
      }

      return null;
    }, [searchParams]);

  const actionPanelSearchParam = getActionPanelSearchParam();

  const generateActionItemSearchParam = (
    id: string,
    actionItemType: ActionItemType,
  ) => {
    const searchParams = new URLSearchParams();
    searchParams.append(actionItemType, id);
    return searchParams.toString();
  };

  const commentParam = searchParams.get('comment');
  const meetingParam = searchParams.get('meeting');
  const fileParam = searchParams.get('file');

  const setCommentParam = (commentId: string) =>
    setSearchParams(prev => ({ ...prev, comment: commentId }));

  const setMeetingParam = (meetingId: string) =>
    setSearchParams(prev => ({ ...prev, meeting: meetingId }));

  const setFileParam = (fileId: string) =>
    setSearchParams(prev => ({ ...prev, file: fileId }));

  return {
    resetActionPanelSearchParams,
    generateActionItemSearchParam,
    actionPanelSearchParam,
    commentParam,
    setCommentParam,
    meetingParam,
    setMeetingParam,
    fileParam,
    setFileParam,
  };
};
