import { TagWithEllipsis } from '@/components/tag/tag-with-ellipsis';
import { SkeletonBox } from '@/components/skeleton/skeleton-box';
import { Inline } from '@/ui/line/line';
import { useProjectExternalLinks } from './use-project-external-links.query';
import { openNewTab } from '@/helpers/open-new-tab';
import { prepareLink } from '@/utils/link';

export const ProjectExternalLinksList = () => {
  const { data, isLoading, isFetched } = useProjectExternalLinks();

  if (isLoading) {
    return <SkeletonBox width="150px" height="20px" />;
  }

  if (isFetched && data?.length === 0) {
    return null;
  }

  const newestLinks = data?.slice(0, 3);

  return (
    <Inline justifyContent="flex-start">
      {newestLinks?.map(link => (
        <TagWithEllipsis
          key={link.id}
          text={link.urlValue}
          variant="transparent"
          startIcon="Link"
          underlinedText
          onClick={() => openNewTab(prepareLink(link.urlValue))}
        />
      ))}
    </Inline>
  );
};
