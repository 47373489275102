import { styled } from 'goober';

export const SkeletonBox = ({
  margin,
  width,
  height,
}: {
  margin?: string;
  width?: string;
  height?: string;
}) => {
  return <SkeletonAnimatedBox width={width} height={height} margin={margin} />;
};

const SkeletonAnimatedBox = styled('div')<{
  width?: string;
  height?: string;
  margin?: string;
}>`
  width: ${({ width }) => width ?? '100%'};
  height: ${({ height }) => height ?? '100%'};
  margin: ${({ margin }) => margin ?? '0'};
  border-radius: 4px;
  ${({ theme }) => theme.animations.skeletonAnimation()};
`;
