import { atom, atomFamily } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import type { Project, ProjectSortOptions } from '@/api/v4/projects.api';
import type { INumberOption, IOption } from '@/types';
import type { ISort } from '@/ui/table/table.types';

import { projectStatusOptions } from './overview/dropdowns/project-status-options';

const { persistAtom } = recoilPersist();

type ProjectTeamOption = IOption;

export type ProjectForm = Omit<Project, 'id' | 'createdDate' | 'creator'> & {
  creator: Project['creator'] | null;
};

export const projectFormState = atom<ProjectForm>({
  key: 'projectFormState',
  default: {
    name: '',
    timeframeStart: null,
    timeframeEnd: null,
    public: true,
    isOwnedByUser: false,
    expectedRoi: null,
    projectKpi: null,
    problemStatement: null,
    projectBudget: null,
    externalExpectedCost: null,
    internalExpectedCost: null,
    status: null,
    team: null,
    creator: null,
    listingsCount: 0,
    tags: [],
    impacts: [],
    isPinned: false,
    mostAdvancedListingStatus: null,
  },
});

export const projectTeamOptionsState = atom<ProjectTeamOption[] | undefined>({
  key: 'projectTeamOptionsState',
  default: undefined,
});

export const projectIdState = atom<string | null>({
  key: 'projectIdState',
  default: null,
});

export const projectsSortState = atom<ISort<ProjectSortOptions>>({
  key: 'projectsSortState',
  default: {
    sort: 'lastEditedDate',
    direction: 'desc',
  },
});

export interface IRangeNumberFilterOption {
  start: IOption;
  end: IOption | null;
}

export interface IProjectFilters {
  tags: IOption[] | null;
  impacts: IOption[] | null;
  listingStatus: IOption[] | null;
  status: IOption[] | null;
  createdFor: IOption[] | null;
  createdBy: INumberOption[] | null;
  createdDate: IRangeNumberFilterOption | null;
  lastEditedDate: IRangeNumberFilterOption | null;
  search: string | null;
}

interface IProjectsFilterOptions {
  tags: IOption[];
  listingStatus: IOption[];
  status: IOption[];
  createdFor: IOption[];
  createdBy: INumberOption[];
  impacts: IOption[];
  createdDate: IRangeNumberFilterOption | null;
  lastEditedDate: IRangeNumberFilterOption | null;
}

const defaultProjectFiltersOptions = {
  tags: [],
  impacts: [],
  listingStatus: [],
  status: projectStatusOptions,
  createdFor: [],
  createdBy: [],
  createdDate: null,
  lastEditedDate: null,
};

export function isProjectFilterKeyContainingPredefinedOptions(
  key: string,
): key is keyof IProjectsFilterOptions {
  return key in defaultProjectFiltersOptions;
}
export const projectsFilterOptionsState = atom<IProjectsFilterOptions>({
  key: 'projectsFilterOptionsState',
  default: defaultProjectFiltersOptions,
});

export const defaultAppliedProjectsFilters: IProjectFilters = {
  tags: null,
  impacts: null,
  listingStatus: null,
  status: null,
  createdFor: null,
  createdBy: null,
  createdDate: null,
  lastEditedDate: null,
  search: null,
};

export const appliedProjectsFiltersState = atom<IProjectFilters>({
  key: 'appliedProjectsFilters',
  default: defaultAppliedProjectsFilters,
});

export const selectedProjectsFiltersState = atom<IProjectFilters>({
  key: 'selectedProjectsFilters',
  default: defaultAppliedProjectsFilters,
});

export const projectOverviewExpandedState = atomFamily<boolean, string[]>({
  key: 'projectOverviewExpandedState',
  default: true,
  effects_UNSTABLE: [persistAtom],
});
