import { atom } from 'recoil';

export const isProjectHeaderEditMode = atom<boolean>({
  key: 'isProjectHeaderEditModeState',
  default: false,
});

export const isProjectHeaderHovered = atom<boolean>({
  key: 'isProjectHeaderHoveredState',
  default: false,
});
