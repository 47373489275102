import { PinItemButton } from '@/features/home/recent-items/pinned-items/pin-item-button';
import { useTogglePinnedItem } from '@/features/home/recent-items/pinned-items/use-toggle-pinned-item.mutation';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';
import { useOptimisticPinProjectUpdate } from '../use-optimistic-pin-project-update';
import type { ProjectHeaderProps } from './project-header';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { styled } from 'goober';
import { Inline } from '@/ui/line/line';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  isProjectHeaderEditMode,
  isProjectHeaderHovered,
} from './project-header.state';
import { Icon } from '@/ui/icons/icon';
import { useProject } from '../use-project.query';
import { useFormContext } from 'react-hook-form';
import type { ProjectHeaderForm } from './edit/use-project-header-form';
import { getEditFormDefaultValues } from './helpers';
import { ProjectNameInput } from './edit/project-name-input';

type ProjectHeaderFirstRowProps = Pick<ProjectHeaderProps, 'isCreateNewMode'>;

export const ProjectHeaderFirstRow = ({
  isCreateNewMode,
}: ProjectHeaderFirstRowProps) => {
  const { data: project } = useProject();
  const isHovered = useRecoilValue(isProjectHeaderHovered);
  const [isEditMode, setIsEditMode] = useRecoilState(isProjectHeaderEditMode);
  const { hasRequiredPermission } = useUserPermissions();
  const { handleOptimisticChange, rollbackToPreviousData } =
    useOptimisticPinProjectUpdate();
  const { toggleItem } = useTogglePinnedItem({
    onMutate: handleOptimisticChange,
    onRollback: rollbackToPreviousData,
  });
  const { reset } = useFormContext<ProjectHeaderForm>();

  const handleEditModeClick = () => {
    reset(getEditFormDefaultValues(project));
    setIsEditMode(true);
  };

  return (
    <Flexbox alignItems="center">
      <ProjectNameInput name={project?.name ?? ''} />
      {!isCreateNewMode && project && (
        <Inline>
          <ButtonBox>
            <PinItemButton
              itemId={project.id}
              itemType="project"
              emptyUnpinnedIcon
              onToggle={toggleItem}
              isPinned={project.isPinned}
            />
          </ButtonBox>

          <EditIcon
            isVisible={
              hasRequiredPermission(PERMISSION.EDIT_PROJECT) &&
              isHovered &&
              !isEditMode
            }
            icon="Edit"
            className="edit-icon"
            role="button"
            dataTestId="edit-project-button"
            onClick={handleEditModeClick}
          />
        </Inline>
      )}
    </Flexbox>
  );
};

const ButtonBox = styled('div')`
  display: block;
`;

const EditIcon = styled(Icon)<{ isVisible: boolean }>`
  cursor: pointer;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;
