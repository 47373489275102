import { SkeletonBox } from '@/components/skeleton/skeleton-box';
import { Inline, Stack } from '@/ui/line/line';

export const ProjectTagsSkeleton = () => {
  return (
    <Inline alignItems="center">
      <SkeletonBox height="60px" width="60px" />
      <Stack gap="15px">
        <SkeletonBox height="16px" width="300px" />
        <SkeletonBox height="16px" width="200px" />
      </Stack>
    </Inline>
  );
};
