import { isEllipsis } from '@/helpers/other';
import type { IconVariant } from '@/ui/icons/icon';
import { Icon } from '@/ui/icons/icon';
import { Inline } from '@/ui/line/line';
import type { GeneralTagProps } from '@/ui/tag/general-tag';
import { GeneralTag } from '@/ui/tag/general-tag';
import { Tooltip } from '@/ui/tooltip/tooltip';
import { styled } from 'goober';
import { forwardRef, useEffect, useRef, useState } from 'react';

interface TagWithEllipsisProps extends GeneralTagProps {
  text: string;
  className?: string;
  startIcon?: IconVariant;
  underlinedText?: boolean;
}

export const TagWithEllipsis = ({
  text,
  startIcon,
  underlinedText,
  ...props
}: TagWithEllipsisProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setIsEllipsisActive(isEllipsis(ref.current));
    }
  }, [text]);

  return (
    <Tooltip content={text} visible={isEllipsisActive}>
      <GeneralTag {...props}>
        <Inline alignItems="center" gap="4px">
          {startIcon && <Icon icon={startIcon} />}
          <EllipsisBox ref={ref} underlined={underlinedText}>
            {text}
          </EllipsisBox>
        </Inline>
      </GeneralTag>
    </Tooltip>
  );
};

const EllipsisBox = styled('div', forwardRef)<{ underlined?: boolean }>`
  ${({ theme }) => theme.mixins.ellipsis};
  ${({ underlined }) => underlined && 'text-decoration: underline'};
`;
