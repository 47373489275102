import type {
  APIRequestBody,
  APIRequestQueryParams,
  APIResponse,
} from './base-fetch';
import { get, post, put, remove } from './base-fetch';

export type CreateProjectListingsPayload =
  APIRequestBody<'/api/v4/projects/{projectId}/listings'>;
type CreateProjectListingResponse =
  APIResponse<'/api/v4/projects/{projectId}/listings'>;

export const createProjectListings = async (
  projectId: string,
  data: CreateProjectListingsPayload,
) =>
  await post<CreateProjectListingResponse>(
    `/v4/projects/${projectId}/listings`,
    data,
  );

type UpdateProjectListingStageResponse =
  APIResponse<'/api/v4/listings/{projectListingId}'>;
export type UpdateProjectListingStagePayload =
  APIRequestBody<'/api/v4/listings/{projectListingId}'>;

export const updateProjectListingStage = async (
  projectListingId: string,
  newListingStageId: string,
) => {
  await put<
    UpdateProjectListingStageResponse,
    UpdateProjectListingStagePayload
  >(`/v4/listings/${projectListingId}`, {
    newListingStatusId: newListingStageId,
  });
};

export const deleteProjectListing = async (projectListingId: string) => {
  await remove<void>(`/v4/listings/${projectListingId}/delete`);
};

export type ListingStage = APIResponse<'/api/v4/listing-statuses'>[number];
type ListingStageParams = APIRequestQueryParams<'/api/v4/listing-statuses'>;

export const fetchListingStages = async (params?: ListingStageParams) =>
  get<ListingStage[]>('/v4/listing-statuses', { params });

export type VoteUseCaseFitPayload =
  APIRequestBody<'/api/v4/listings/{projectListingId}/vote-use-case-fit'>;

export const voteProjectListingUseCaseFit = async (
  projectListingId: string,
  data: VoteUseCaseFitPayload,
) =>
  await post<void>(`/v4/listings/${projectListingId}/vote-use-case-fit`, data);
