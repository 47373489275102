import { Stack } from '@/ui/line/line';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';
import { styled } from 'goober';
import { forwardRef } from 'react';
import { isProjectHeaderEditMode } from './project-header.state';
import { useRecoilValue } from 'recoil';

interface DetailBlockProps {
  title: string;
  value: string;
  editModeComponent?: React.ReactNode;
}

export const DetailBlock = ({
  title,
  value,
  editModeComponent,
}: DetailBlockProps) => {
  const isEditable = Boolean(editModeComponent);
  const isEditing = useRecoilValue(isProjectHeaderEditMode);

  return (
    <Stack gap="4px">
      {isEditable && isEditing ? (
        editModeComponent
      ) : (
        <EllipsisTextTooltip
          Component={<DetailHeader isEditing={isEditing} />}
          text={value}
        />
      )}
      <DetailLabel>{title}</DetailLabel>
    </Stack>
  );
};

const DetailLabel = styled('span')`
  font-family: ${({ theme }) => theme.fonts.wallop};
  color: ${({ theme }) => theme.colors.gray.c11};
  white-space: nowrap;
  font-size: 14px;
  line-height: 16px;
`;

const DetailHeader = styled('h3', forwardRef)<{ isEditing?: boolean }>`
  ${({ theme }) => theme.mixins.ellipsis}
  font-family: ${({ theme }) => theme.fonts.wallop};
  ${({ isEditing }) => isEditing && 'padding: 6px 0;'}
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  max-width: 82px;

  @media (min-width: 1440px) {
    max-width: 150px;
  }

  @media (min-width: 1620px) {
    max-width: 190px;
  }
`;
