import { useFormContext } from 'react-hook-form';

import type { ProjectHeaderForm } from './use-project-header-form';
import { useEffect, useState } from 'react';
import {
  formatCalendarDate,
  parseNullishStringToDate,
} from '@/helpers/format-date';
import { CustomInputAsyncSelect } from '@/ui/date-picker/custom-input/custom-input-async-select';
import { DatePicker } from '@/ui/date-picker/date-picker';
import { GENERAL_DATE_FORMAT } from '@/constants';
import { getCustomSharedProps } from '@/ui/select/select-shared';
import { colors } from '@/theme/colors';
import { styled } from 'goober';

export const TimeFramePicker = () => {
  const {
    setValue,
    formState: { defaultValues },
  } = useFormContext<ProjectHeaderForm>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const timeFrameDefaultValue = defaultValues?.timeframe;
  const { components, styles } = getCustomSharedProps({
    variant: 'small',
    dropdownIndicatorColor: colors.basics.black,
  });

  useEffect(() => {
    if (!timeFrameDefaultValue) return;
    const { timeframeStart, timeframeEnd } = timeFrameDefaultValue;
    setStartDate(parseNullishStringToDate(timeframeStart));
    setEndDate(parseNullishStringToDate(timeframeEnd));
  }, [timeFrameDefaultValue]);

  const handleOnChange = async (date: [Date, Date | null]) => {
    const [start, end] = date;
    setStartDate(start);
    setEndDate(end);

    setValue(
      'timeframe',
      {
        timeframeStart: formatCalendarDate(start, 'yyyy-MM-dd') ?? '',
        timeframeEnd: formatCalendarDate(end, 'yyyy-MM-dd') ?? '',
      },
      {
        shouldDirty: true,
      },
    );
  };

  return (
    <SelectWrapper>
      <DatePicker
        onChange={handleOnChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        dateFormat="yyyy-MM-dd"
        customInput={
          <CustomInputAsyncSelect
            displayFormat={GENERAL_DATE_FORMAT}
            isTimeFrame
            components={{ ...components }}
            styles={styles}
            dataTestId="time-frame-picker"
          />
        }
      />
    </SelectWrapper>
  );
};

const SelectWrapper = styled('div')`
  width: 140px;
`;
