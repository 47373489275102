import { useEffect } from 'react';
import { styled } from 'goober';
import { useRecoilValue } from 'recoil';

import type { ListingStage } from '@/api/v4/project-listings.api';
import { TOPBAR_HEIGHT } from '@/features/topbar/topbar';
import { withDraggableProvider } from '@/hoc/with-draggable-provider';
import { DraggableTable } from '@/ui/table/draggable-table/draggable-table';

import { projectIdState } from '../../project.state';
import { useProjectListings } from '../use-project-listings.query';

import { CompanyDragDrop } from './company-drag-drop/company-drag-drop';
import { useProjectListingsTableConfiguration } from './table/use-project-listings-table-configuration';
import { StageManagementEmptyState } from './stage-management-empty-state';
import type { TabStage } from './use-stage-tabs-config';
import { useSortStageListings } from './use-sort-stage-listings';
import type { ProjectCompaniesSort } from '@/api/v4/projects.api';

export const ListingStageTabContent = withDraggableProvider(
  ({ tab, dragTo }: { tab: TabStage; dragTo: ListingStage | undefined }) => {
    const projectId = useRecoilValue(projectIdState);
    const { handleStageContentSort, sortData, resetProjectListingSort } =
      useSortStageListings();

    useEffect(() => {
      resetProjectListingSort();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    const { projectListings } = useProjectListings(projectId);

    const { headers, rows } = useProjectListingsTableConfiguration(tab);

    if (!projectId) return null;

    return (
      <TabContent data-testid="stage-management-tab-content">
        <TabContainer>
          {rows.length === 0 ? (
            <StageManagementEmptyState
              projectId={projectId}
              isProjectEmpty={!Boolean(projectListings.length)}
            />
          ) : (
            <>
              <TableWrapper>
                <DraggableTable<ProjectCompaniesSort>
                  rows={rows}
                  header={headers}
                  sort={{
                    direction: sortData.direction,
                    sort: sortData.sort,
                    onSort: handleStageContentSort,
                  }}
                />
              </TableWrapper>
              {dragTo && (
                <DragDropContainer>
                  <CompanyDragDrop dragTo={dragTo} />
                </DragDropContainer>
              )}
            </>
          )}
        </TabContainer>
      </TabContent>
    );
  },
  'touch',
);

const TabContainer = styled('div')`
  display: flex;
  gap: 36px;
  width: 100%;
`;

const TableWrapper = styled('div')`
  flex: 1;
`;

const DragDropContainer = styled('div')`
  margin: 55px 1px 1px 1px;
`;

const TabContent = styled('div')`
  padding: 30px 30px 0px 30px;
  display: flex;
  width: 100%;
  height: calc(100vh - ${TOPBAR_HEIGHT} - 105px);
  overflow: hidden;
`;
