import { forwardRef, useMemo } from 'react';
import { styled } from 'goober';

import { colors } from '@/theme/colors';
import { Flexbox } from '@/ui/flexbox/flexbox';
import { colorIcon } from '@/ui/icons/functions';
import { Icon } from '@/ui/icons/icon';
import { EllipsisTextTooltip } from '@/ui/table/infinite-table/ellipsis-text-tooltip';

import { useProject } from '../use-project.query';
import { SkeletonText } from '@/ui/table/infinite-table/skeleton-row';
import { Inline, Stack } from '@/ui/line/line';
import { PERMISSION } from '@/user/permissions/permission.type';
import { useUserPermissions } from '@/user/permissions/use-user-permissions';

import { ProjectExternalLinksWidget } from '../external-links/project-external-links-widget';

import { useSetRecoilState } from 'recoil';
import { modalState } from '@/state/modal.state';
import { ProjectTagsWidget } from '../project-tags-widget';
import { FixedPanelWithShadowLayer } from '@/components/fixed-panel-with-shadow-layer/fixed-panel-with-shadow-layer';
import { ProblemStatementWidget } from './problem-statement-widget';
import { KpiWidget } from './kpi-widget';
import { ExpectedRoiWidget } from './expected-roi-widget';
import { ProjectImpactWidget } from './impact-widget';
import { ProjectCurrencyWidget } from './project-currency-widget';

const WIDGET_HEIGHT = 220;
const WIDGET_WIDTH = 800;
const GAP = 24;

export const ProjectSidebar = ({ onClose }: { onClose: () => void }) => {
  const { data: project, isLoading, isFetched } = useProject();
  const { hasRequiredPermission } = useUserPermissions();
  const setModal = useSetRecoilState(modalState);
  const hasEditPermission = hasRequiredPermission(PERMISSION.EDIT_PROJECT);

  const widgetDefaultProps = useMemo(() => {
    return {
      projectId: project?.id,
      isDataLoading: isLoading,
      hasEditPermission,
      widgetWidth: WIDGET_WIDTH,
      widgetHeight: WIDGET_HEIGHT,
    };
  }, [hasEditPermission, isLoading, project?.id]);

  return (
    <SidebarWrapper onOutsideClick={onClose}>
      <Header
        name="project-sidebar-header"
        data-testid="project-sidebar-header"
        alignItems="center"
        justify="space-between"
        padding="0 30px"
      >
        <TitleContainer
          name="project-sidebar-title"
          alignItems="center"
          gap="12px"
          onClick={onClose}
        >
          {isLoading ? (
            <SkeletonText width="150px" />
          ) : (
            <>
              <Icon icon="ArrowRightSections" color={colors.gray.c12} />
              <EllipsisTextTooltip
                text={project?.name}
                Component={<TitleValue />}
              />
            </>
          )}
        </TitleContainer>
      </Header>
      <ContentWrapper data-testid="project-sidebar-content">
        <Stack gap={`${GAP}px`}>
          <ProblemStatementWidget
            {...widgetDefaultProps}
            problemStatement={project?.problemStatement}
          />

          {isFetched && (
            <>
              <Inline gap={`${GAP}px`}>
                <FirstWidget
                  height={WIDGET_HEIGHT * 2 + GAP}
                  listMaxHeight={342}
                />
                <Stack gap={`${GAP}px`}>
                  <MiddleWidget
                    {...widgetDefaultProps}
                    widgetWidth={WIDGET_WIDTH / 2 - GAP / 2}
                    impacts={project?.impacts}
                  />
                  <LastWidget
                    tags={project?.tags ?? []}
                    disabled={!hasEditPermission}
                    testId="tags-widget"
                    onEditClick={() =>
                      project?.id &&
                      setModal({
                        state: 'manageTags',
                        type: 'project',
                        entityId: project.id,
                      })
                    }
                  />
                </Stack>
              </Inline>

              <KpiWidget
                {...widgetDefaultProps}
                projectKpi={project?.projectKpi}
              />
              <ExpectedRoiWidget
                {...widgetDefaultProps}
                expectedRoi={project?.expectedRoi}
              />
              <ProjectCurrencyWidget
                {...widgetDefaultProps}
                widgetHeight={106}
                internalExpectedCost={project?.internalExpectedCost}
                externalExpectedCost={project?.externalExpectedCost}
                projectBudget={project?.projectBudget}
              />
            </>
          )}
        </Stack>
      </ContentWrapper>
    </SidebarWrapper>
  );
};

const SidebarWrapper = styled(FixedPanelWithShadowLayer)`
  right: 0;

  ${({ theme }) =>
    theme.animations.slideInHorizontally('right', '800px', '0.3s')};

  @media (min-width: 1600px) {
    min-width: unset;
    max-width: 1020px;
  }
`;

const Header = styled(Flexbox)`
  background-color: ${({ theme }) => theme.colors.basics.white};
`;

const TitleContainer = styled(Flexbox)`
  ${({ theme }) => theme.typography.acrossPlatform.minimizedHeader}
  ${({ theme }) => colorIcon(theme.colors.basics.black)}
  cursor: pointer;
  height: 70px;
`;

const TitleValue = styled('div', forwardRef)`
  ${({ theme }) => theme.mixins.ellipsis}
  max-width: 420px;
`;

const ContentWrapper = styled('div')`
  padding: 24px;
  max-height: calc(100vh - 70px);
  overflow-y: auto;
  min-width: 800px;
  ${({ theme }) => theme.mixins.scrollbar};
`;

const FirstWidget = styled(ProjectExternalLinksWidget)`
  width: ${WIDGET_WIDTH / 2 - GAP / 2}px;
`;

const MiddleWidget = styled(ProjectImpactWidget)`
  min-width: ${WIDGET_WIDTH / 2 - GAP / 2}px;
  max-width: ${WIDGET_WIDTH / 2 - GAP / 2}px;
  max-height: ${WIDGET_HEIGHT}px;
`;

const LastWidget = styled(ProjectTagsWidget)`
  min-width: ${WIDGET_WIDTH / 2 - GAP / 2}px;
  max-width: ${WIDGET_WIDTH / 2 - GAP / 2}px;
  min-height: ${WIDGET_HEIGHT}px;
`;

export const SkeletonWidget = styled('div')<{ width: number }>`
  height: ${WIDGET_HEIGHT}px;
  width: ${({ width }) => width}px;
  ${({ theme }) => theme.animations.skeletonAnimation()};
`;
