import type { Project } from '@/api/v4/projects.api';

export const getEditFormDefaultValues = (project?: Project | null) => {
  return {
    name: project?.name ?? null,
    timeframe:
      project?.timeframeStart && project?.timeframeEnd
        ? {
            timeframeStart: project.timeframeStart,
            timeframeEnd: project.timeframeEnd,
          }
        : null,
    status: project?.status ?? null,
    createdFor: project?.team
      ? { value: project.team.id, label: project.team.name }
      : null,
  };
};
