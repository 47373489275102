import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { SingleValue } from 'react-select';
import { useRecoilValue } from 'recoil';

import { withPermission } from '@/hoc/with-permission';
import type { SelectOptionValue } from '@/ui/select/select';
import { Select } from '@/ui/select/select';
import { isTableItemDraggingState } from '@/ui/table/draggable-table/draggable-table.state';
import { PERMISSION } from '@/user/permissions/permission.type';

import type { IUseCasesVote } from '../types';

import { UseCaseOption } from './use-case-fit-option';
import { useVoteUseCaseFit } from './use-vote-use-case-fit.mutation';

enum UseCaseFit {
  notFit = 'not_a_fit',
  possibleFit = 'possible_fit',
  goodFit = 'good_fit',
}

export const UseCaseFitSelect = withPermission(
  ({
    useCaseFit,
    projectListingId,
    useCaseVotes,
  }: {
    useCaseFit: string | null;
    projectListingId: string;
    useCaseVotes: IUseCasesVote[];
  }) => {
    const { t } = useTranslation('projects');

    const voteUseCaseFit = useVoteUseCaseFit(projectListingId);

    const isTableItemDragging = useRecoilValue(isTableItemDraggingState);

    const options = useMemo(() => {
      const getVotes = (useCaseFit: UseCaseFit) =>
        useCaseVotes
          .filter(vote => vote.useCaseFit === useCaseFit)
          .map(vote => ({
            name: `${vote.firstName} ${vote.lastName}`,
            description: vote.team,
          }));

      return [
        {
          value: UseCaseFit.goodFit,
          label: (
            <UseCaseOption
              label={t`goodFit`}
              icon="UseCaseGoodFit"
              voted={getVotes(UseCaseFit.goodFit)}
            />
          ),
        },
        {
          value: UseCaseFit.possibleFit,
          label: (
            <UseCaseOption
              label={t`possibleFit`}
              icon="UseCasePossibleFit"
              voted={getVotes(UseCaseFit.possibleFit)}
            />
          ),
        },
        {
          value: UseCaseFit.notFit,
          label: (
            <UseCaseOption
              label={t`notAFit`}
              icon="UseCaseNotAFit"
              voted={getVotes(UseCaseFit.notFit)}
            />
          ),
        },
      ];
    }, [t, useCaseVotes]);

    const vote = options.find(useCase => useCase.value === useCaseFit);

    const [selectedVote, setSelectedVote] = useState<
      SingleValue<SelectOptionValue<UseCaseFit>>
    >(vote ?? null);

    const handleUseCaseFitChange = async (
      newUseCaseFit: SingleValue<SelectOptionValue<UseCaseFit>>,
    ) => {
      setSelectedVote(newUseCaseFit);

      if (!newUseCaseFit) {
        return;
      }

      await voteUseCaseFit.mutateAsync({
        useCaseFit: newUseCaseFit.value,
      });
    };

    return (
      <Select<UseCaseFit>
        options={options}
        onChange={handleUseCaseFitChange}
        value={selectedVote}
        placeholder={t`voteHere`}
        optionPadding="0"
        hideMenu={isTableItemDragging}
        menuPortalTarget={document.body}
        styles={{
          menu: base => ({
            ...base,
            [`@media (max-width: 1180px)`]: {
              width: 'min-content',
            },
          }),
          singleValue: base => ({
            ...base,
            '.hidden-inside-single-value': {
              display: 'none',
            },
          }),
        }}
      />
    );
  },
  PERMISSION.VOTE_USE_CASE_FIT,
);
